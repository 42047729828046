.dashboard-btn {
   display: flex;
   background-color: $primary-color;
   width: 100%;
   
   border-radius: $site-border-radius;
   font-size: $text-l;
   font-family: $title-font !important;
   color: $element-color;
   padding: $spacing-m $spacing-l;
   align-items: center;
   min-height: 6rem;
   gap: $spacing-l;
   cursor: pointer;

   &:hover {
      background-color: $hover-color;
   }
   
}

#dashboard-link-btn{
   text-align: start !important;
   padding-bottom: $spacing-s !important;
}

.dashboard-link-icon{
   vertical-align: -0.25em !important;
}

.dashboard-link {
   font-size: $text-m !important;
   text-align: start !important;
   font-family: $title-font !important;
   color: $element-color !important;
   text-decoration: none !important;
   padding: 0;
}

.dashboard-btn-text{
   font-size: $text-m !important;
   font-family: $title-font !important;
   color: $element-color !important;
   text-decoration: none !important;
}

.dashboard-back-btn {
   font-size: $text-xl !important;
   font-family: $title-font !important;
   color: $primary-color !important;
   text-decoration: none !important;

   &:hover {
      color: $hover-color !important;

   }
}

#bygge-btn{
   width: 4rem;
   height: auto;
}


.document-link {
   cursor:pointer;
   font-size: $text-l;
   margin-bottom:$spacing-s;
}

.document {
   margin-top:$spacing-m;
   padding-left:$spacing-s;

   ol {
      padding-left:$spacing-xl;
      li{
         
         ul{
            padding-left:$spacing-xl;
            list-style: circle;
         }
      }
   } 

   a {
      color: $primary-color;
      text-decoration: none;
   }

   p {
      margin: 0 0 $spacing-s;
  }
}

