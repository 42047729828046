@import 'src/assets/style/scss/base/variables.scss';

.bkbutton { 
  background-color: $accent-color;
  font-family: $title-font;
  font-size: $text-m;
  color: $element-color;
  padding: $spacing-xxs $spacing-s;
  overflow: hidden;
  cursor: pointer;
  border-radius: $site-border-radius;
  text-align: center;
  width: fit-content;
  white-space: nowrap;

  &:hover {
    background-color: $hover-color!important;
    color: $element-color !important;
  }
  
}

.bkbutton-disabled {
  background-color: $dark-gray !important;
  cursor: auto !important;
}