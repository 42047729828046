@media (max-width: 575.98px) {
    html {
      font-size: 12px;
    }

    .navbar-container {
      margin: $spacing-m $spacing-l
    }

    .main-container{
      padding: 0 $spacing-l;
    }

    .bk-text-blue-big {
      font-size: $text-lm;
    }

    .title-row{
      display: flex;
      flex-direction: column;
      gap: $spacing-s;
    }
  }
  
  @media (min-width: 576px) and (max-width: 767.98px){
    html {
      font-size: 12.5px;
    }

    .navbar-container {
      margin: $spacing-m $spacing-l
    }

    .main-container{
      padding: 0 $spacing-l;
    }

    .bk-text-blue-big {
      font-size: $text-l;
    }

    .title-row{
      display: flex;
      flex-direction: column;
      gap: $spacing-s;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    html {
      font-size: 13px;
    }

    .navbar-container {
      margin: $spacing-m $spacing-l
    }

    .main-container{
      padding: 0 $spacing-l;
    }

  }
  
  @media (min-width: 992px) and (max-width: 1274.98px) {
    html {
      font-size: 13.5px;
    }

    .navbar-container {
      margin: $spacing-m $spacing-xl
    }

    .main-container{
      padding: 0 $spacing-xl;
    }

  }
  
  @media (min-width: 1275px) and (max-width: 1399.98px) {
    html {
      font-size: 14px;
    }
  }
  
  @media (min-width: 1400px) and (max-width: 1599.98px) {
    html {
      font-size: 14.5px;
    }

  }

  @media (min-width: 1600px) {
    html {
      font-size: 15px;
    }

    .navbar-container {
      margin: $spacing-m $spacing-website-xxl
    }

    .main-container{
      padding: 0 $spacing-website-xxl;
    }
  }