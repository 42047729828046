.calculation-dialog-frame {
   max-height:90vh;
   width:95vh;
}

.calculation-dialog-header-row {
   display:flex;
   margin-bottom:4px;
   font-weight: bold;
}


.calculation-dialog-row {
   display:flex;
   margin-bottom:4px;
   cursor:pointer;
}

.calculation-dialog-row:hover {
   background-color: $accent-color;
}
