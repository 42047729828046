.invisible{
 opacity: 0 !important;
}

.subtitle{
   font-size: $text-m;
   font-weight: bold;
}

.text-align-end{
   text-align:right;
}

.area-table-bra-header-row{
   margin-top: $spacing-m;
}
.area-table-bra-header{
   text-align: center;
}


.area-table-input-floor-container {
   display: grid;
   grid-template-columns: 0.20fr 1fr;
   margin-left: $spacing-xs;
   margin-right: $spacing-s;
   font-size: $text-s;
}

.area-table-input-rooms-container {
   display: flex;
   flex-direction: column;
   gap: $spacing-xs;
}

.area-table-room{
   display: grid;
   grid-template-columns: 0.2fr 3fr;
}

.area-table-input-row-rooms {
   display: grid;
   grid-template-columns: 0.42fr 1.5fr repeat(3,0.55fr);
   font-size: $text-s;
   margin-left: $spacing-xs;
   margin-right: $spacing-s;
   align-items: top;
}

.area-table-input-row {
   display: grid;
   grid-template-columns: 0.65fr repeat(4,0.47fr) 0.1fr 0.9fr;
   gap: $spacing-s; 
   font-size: $text-s !important;
   margin-left: $spacing-xs;

   .input-container {
      width:100%;
      font-size:$text-s;

      .disabled {
         border:1px solid $placeholder-color;
         border-radius:$site-border-radius;
         color:black;
      }

      .sum {
         background-color:$light-gray;
      }

      .totalsum{
         background-color:$light-gray;  
      }

      .input-numeric {
         margin-bottom:$spacing-xs;
         font-size:$text-s;
         text-align:right;
      }

      i {
         font-size: $spacing-m;
         right: 4px; 
         position: absolute;
         transform: translate(0, -50%);
         top: 10px;
         width: 25px;
         text-align: center;
         font-style: normal;
         color:black;
       }
   }
   
      input {
         width:100%;
         font-size:$text-s;
      }

}

.area-table-floor-description{
   font-size:$text-s !important;
   padding-top:$spacing-xs !important;
   padding-bottom:$spacing-xxs !important;
   margin-top:$spacing-xxs !important;
   border:$border-width solid rgb(118, 118, 118) !important;
   border-radius:$site-border-radius;
}


