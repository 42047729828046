.dialog-container {
    background-color: $element-color;
    padding: $spacing-l $spacing-xl;
}

.dialog-title {
    font-size: $text-m;
    font-family: $title-font;
    color: $primary-color;
}

.new-service-column {
    background-color: $element-color;
    overflow-y: scroll;
    height: 35rem;
    padding: $spacing-s $spacing-m;

    &::-webkit-scrollbar {
      
    }
  }

  .new-service-button {    
    background-color: $secondary-color;
    color: $text-color;
    border-radius: $site-border-radius;
    font-size: $text-xs;
    font-family: $title-font;
    font-weight: 500;
    margin: $spacing-s 0;
    padding: $spacing-xs $spacing-s;
    align-items: center;
    cursor:pointer;

    &:hover {
      background-color:$hover-color;
      color: $element-color;
    }
  }

  .new-service-button-selected {
    background-color:$hover-color;
    color: $element-color;
  }


  .selectEmpty {
    margin-top: $spacing-xxs;
  }

.dialog-header {
    color: $element-color;
    font-family: $title-font !important;
    background:$primary-color;
    padding: $spacing-s $spacing-l !important;
    font-size: $text-s !important;
}

.dialog-link {
    font-size: $text-m;
    font-family: $title-font;
    color: $accent-color;
    text-decoration: underline;

    &:hover {
        color: $hover-color;
        cursor: pointer;
    }
}

#confirmation-dialog-title{
    height: 60px;

    h2 {
      font-size: $text-xl !important;
    }
}

.dialog-content{
  padding: 0 $spacing-m;
}

.dialog-actions{
  padding: $spacing-l !important;
}

.dialog-button{
    background-color: $accent-color;
    color: $element-color;
    font-size: $text-m;
    font-family: $title-font;
    text-align: center;
    border: none;
    border-radius: $site-border-radius;
    text-decoration: none;
    padding: $spacing-xxs $spacing-s;
  
    &:hover {
      background-color: $hover-color;
      color: $element-color;
      cursor: pointer;
    }
  }

.contact-content {
  height: $row-height !important;
  font-size: $text-s !important;
  border-radius: $site-border-radius !important;
  background: $input-background !important; 
  border: $border-width solid $background-color !important; 
}
.contact-autoTextBox {
  font-size: $text-s !important;
  border-radius: $site-border-radius !important;
  background: $input-background !important; 
  border: $border-width solid $background-color !important; 
}

.register-content{
  display: flex;
  flex-direction: column;
  gap: $spacing-s;
}
.register-title{
  font-size: $text-lm;
  font-family: $title-font;
  color: $primary-color;
  font-weight: $font-bold;
  letter-spacing: $letter-spacing;
}