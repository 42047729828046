.upload-dialog-frame{
   max-height:60vh;
   max-width:60vw;
   display:flex;
   text-align:center;
   overflow:hidden;
   height: 100%;
   width:100%;
}

.upload-dialog-help-text{
   color:$primary-color;
}

.upload-dialog-image-container{
   display: table;
   height: 100%;
   width:100%;


   .upload-dialog-image{
      text-align:center;
      //max-height:45vh;
     // object-fit: contain;

     img{
         //height:100%;
         //width: 100%;
         display: block;
         transform-origin: top left;
         white-space: nowrap;
         border:1px solid #777;
         max-height:60vh;
         object-fit: contain;
       }
   }  
}

.upload-button-frame{
   flex:1;
}

.upload-dialog-border {
   display: flex;
   flex-direction: column;
   border: $border-width dashed $primary-color;
   border-radius: $site-border-radius;
   cursor: pointer;
   align-items: center;
   justify-content: center;
   padding: $spacing-l;
   margin: $spacing-s;
   height: 20rem;
}

.upload-buttons {
  display: flex;
}

.upload-header{
white-space: nowrap;
}
.upload-header-row{
   display: flex;
   flex-direction: row;
   gap: $spacing-sm;
   align-items: center;
}

.upload-buttons > div:first-child {
   margin-right: auto;
 }

 .upload-dialog-rotate-icon {
    float:right;
 }

 .upload-dialog-transform-buttons{
   float:right;
   margin-bottom: $spacing-s;
 }

 .upload-dialog-camera-icon{
    float:right;
    color:$primary-color;
    cursor:pointer;
 }