.select-box-item {
   display:flex;

   .select-box {
    flex:1;
    padding-top:2px;
    padding-right:5px;
       .the-box {
          float:right;
          border:1px solid $primary-color;
          border-radius:2px;
          min-width:20px;
          min-height:20px;
          max-width:20px;
          max-height:20px;
          cursor:pointer;
       }

       .the-box:hover {
          background-color: $hover-color;
       }

       .selected-box {
          position: relative;
          overflow: hidden;
       }

       .selected-box:before, .selected-box:after {
          position: absolute;
          content: '';
          background: $primary-color;
          display: block;
          width: 100%;
          height: 2px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
      }
      
      .selected-box:after {
          -webkit-transform: rotate(45deg);  
          transform: rotate(45deg);
      }


   }

   .select-box-caption {
    text-align: left;
    flex:1;
   }
}