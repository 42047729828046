// .simple-calc-container{
//   flex: auto;
//   //text-align:center;
//   width:100%;
//   padding-left:5%;
//   padding-right:5%;
//   background: linear-gradient(90deg, rgba(106,157,158,1) 0, rgba(135,176,177,1) 50%, rgba(212,228,228,1) 100%);
//   //padding-bottom:100px;
// }

.simple-calc-wrapper{
  width:1200px;
  min-height:800px;
  margin-left:auto;
  margin-right: auto;
  margin-top:30px;
  //margin-bottom:60px;
  padding:10px;
  background-color: white;
}

.simple-calc-header {
  color: white;
  background-color: $primary-color;
  font-size: 30px;
  padding-left:20px;
  font-family: Arial;
}

.simple-calc-section{
  margin-left:10px;
  margin-top:20px;
}


.simple-calc-section-header {
  font-size: $text-l;
   font-weight: bold;
   color:$text-color;
   font-family:$report-font;
   background: $light-gray;
   padding-left:15px;
   padding-bottom: 5px;
   display:flex;
   height:33px;
   padding-top:0;
   border-radius: 5px 5px 0 0;
   width: 100%;
   margin-top: 20px;
  // background-color: #384552;

  padding-left:10px;
  display:flex;

}

// .simple-calc-section-input {
//   margin-left:40px;
//   font-size: 18px;
//   margin-top:4px;
//   input {
//     min-width:600px;
//     padding:6px;
//     font-size:0.8em;
//   }
// }

.simple-calc-section-row {
  display:flex;
  margin-left:20px;
  margin-top:4px;
}

.simple-calc-section-row-blue {
  display:flex;
  margin-left:20px;
  margin-top:4px;
  color:white;
  background-color: #384552;
}


.simple-calc-section-main-input {
  flex:12;
  padding-left:10px;
  display:flex;

  input {
    width:100%;
    padding:6px;
    background-color: #384552;
    color:white;
    margin-top:-2px;
    font-size:1em;
  }
}

.simple-calc-description-main-title {
  flex:3;
  padding-left:10px;
  //padding-top:4px;
}




.simple-calc-description-row{
  display:flex;
  margin-left:30px;
  margin-top:4px;
}

.simple-calc-description-wrapper{
  padding-bottom:10px;
  margin-bottom:20px;
  padding-right:20px;
}


.simple-calc-description-title {
  flex:3;
  padding-left:10px;
}

.simple-calc-description-type{
  flex:12;
  padding-left:10px;
  display:flex;

  .big-text-area {
    width:100%;
  }

  .simple-calc-description-type-text{
    flex:7;
  }
  
  .simple-calc-description-upload-frame {
    flex:3;
    height:95%;
    border:3px dashed rgba(106,157,158,1);
    border-radius: 5px;
    cursor: pointer;
    text-align: center;

    .frame-header{
      font-size: 18px;
      font-weight: bold;
      margin-top:1vw;
      color: rgba(106,157,158,1);
    }

    .frame-text {
      font-size: 12px;
      color:black;
    }
  }
  .large {
    height:200px;
    padding-top:30px;
  }

  .image-frame {
    flex:3;
    max-height:95%;
    cursor: pointer;
    text-align: right;
    border:1px solid #aaa;
    border-radius:3px;
    
    img {
      height: 146px; 
      width: 100%; 
      object-fit: contain;

    }
  }

  .large-image-frame {
    flex:1;
    max-height:95%;
    min-height: 300px;
    min-width:200px;
    cursor: pointer;
    border:1px solid #aaa;
    border-radius:3px;

    img {
      height: 100%; 
      width: 100%; 
      object-fit: contain;
      
      
    }
  }
}




.simple-calc-description-type-row{
  flex:14;
  
  input {
    width:100%;
    padding:6px;
  }
}

.simple-calc-description-text{
  flex:10;
  padding-left:10px;
  input {
    width:100%;
    padding:6px;    
  }
}


.simple-calc-info-type{
  flex:5;
  padding-left:10px;
  input {
    width:100%;
    padding:6px;
    font-size:1em;   
  }
}


.simple-calc-info-text{
  flex:10;
  padding-left:10px;
  input {
    width:100%;
    padding:6px;
    font-size:1em;    
  }
}



.simple-calc-action-menu {
  flex:1;
  margin-left:10px;
  padding-top: 2px;
  cursor:pointer;
}


.simple-calc-calc-heading{
  display:flex;
}

.simple-calc-calc-heading-wrapper {
  text-align: center;
  background-color :#a0c2de;
  border-left:1px solid  white;
  border-bottom:1px solid white;
  vertical-align: center;
}

.simple-calc-calc-heading-item {
  color:$text-color;
  text-align: center;
  font-weight: bold;
  font-size: $text-xs;
}

.simple-calc-border-right {
  border-right:1px solid  white;
}

.simple-calc-calc-heading-row {
  display:flex;
  border-top:1px solid  white;
}

.simple-calc-vertical-align {
  padding-top:26px;
}

.simple-calc-vertical-align-small {
  padding-top:13px;
}


.simple-calc-heading-menu{
  flex:0.2;
  padding-left:20px;
  cursor:pointer;
}

.simple-calc-category-wrapper {
  margin-bottom:10px;
}

.simple-calc-category {
  border-left:1px solid  #E3EDF9;
  border-bottom:1px solid  #E3EDF9;
  font-weight: bold;
}

.simple-calc-category-description {
  padding-right:4px;
}

.simple-calc-category-row {
  border-left:1px solid #E3EDF9;
  border-bottom:1px solid #E3EDF9;
}

.simple-calc-category-value{
  padding-right:7px;
}

.simple-calc-input{
  background:transparent;
  border:0;
  width:100%;
  font-size: 1em;
  padding-right:6px;
}

.simple-calc-input-reduced {
  background:transparent;
  border:0;
  width:calc(100% - 20px);
  font-size: 1em;
}

.simple-calc-select{
  background:transparent;
  border:0;
  width:100%;
  font-size: 1em;
  text-align-last:right;

  option {
    direction:rtl;
  }
}

.sc-color-main-even {
  background-color: $light-gray;
}

.sc-color-light-even {
  background-color: $light-gray;
}

.sc-color-main-odd {
  background-color: $light-gray;
}

.sc-color-light-odd {
  background-color: $light-gray;
}

.simple-calc-result-row {
  margin-left: auto;
  margin-right: auto;
  display:flex;
  width:50%;
  border: 1px solid #dbe1e2;
  font-weight: bold;
  font-size: 1.2em;

  .simple-calc-result-heading {
    flex:1;
    background-color:$light-gray;
    color:$text-color;
    padding: 4px;
  }

  .simple-calc-result-value-even {
    text-align:right;
    flex:1;
    padding: 4px;
  }

  .simple-calc-result-value-odd {
    text-align:right;
    flex:1;
    background-color: $placeholder-color;
    padding: 4px;
  }
}

.simple-calc-icon-printer {
  float: right;
  width: 4%;
  cursor:pointer;
  margin-right:40px;
  margin-top:4px;
}

.simple-calc-modal-tip{
  font-size: 14px;
}

.simple-calc-example-link{
  font-size: 11px;
  color: red;
}