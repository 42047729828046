.register-container {
  text-align: center;
  background-color: $element-color;
}



.register-control {
  width: 100% !important;
  color: $primary-color !important;
    font-size: $text-m !important;
    font-family: $title-font !important; 
}


.register-button {
  
  border: none;
  background-color: $accent-color;
  font-family: $title-font;
  font-size: $text-m;
  color: $element-color;
  padding: $spacing-xs $spacing-m;
  overflow: hidden;
  cursor: pointer;
  border-radius: $site-border-radius;
  text-align: center;

  &:hover {
    background-color: $hover-color!important;
    color: $element-color !important;
  }
}

// @media only screen and (max-width: 575px) { 
//   #register-form {
//     margin-top: $spacing-m;
//   }
// }

.register-title {
  color: $primary-color;
  font-size: $text-l;
  font-family: $title-font;
  font-weight: bold;
}

.register-text {
  font-family: $title-font !important;
  color: $primary-color !important;
  font-size: $text-l !important;
}

.register-subtext {
  font-family: $title-font;
  color: $primary-color;
  font-size: $text-m;
}

.register-text-muted {
  font-family: $title-font;
  color: $primary-color;
  font-size: $text-m !important;
  text-align: end !important;
}

