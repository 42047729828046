$sidebar-bg-color: $primary-color;
$submenu-bg-color: $hover-color;
$icon-bg-color: transparent;
$sidebar-color: $element-color;
$sidebar-width: 15rem;


@import '~react-pro-sidebar/dist/scss/styles.scss';


.admin-container {
   display: grid;
   grid-template-columns: 0.6fr 9fr;
   overflow-x: hidden;
   background-color: $element-color;
}


.admin-menu-icon-with-number {
   position: relative;
   line-height: $spacing-m !important;

   .admin-menu-icon-number {
      position: absolute;
      width: $spacing-m;
      height: $spacing-m;
      color: $input-background;
      text-align: center;
      background-color: $error-color;
      border-radius: 50%;
      top: -10%;
      right: 75%;
      font-weight: bold;
      font-size: $text-xs !important;
   }

}

.admin-menu-text {
   font-size: $text-s !important;
   font-family: $title-font;
}

.admin-menu-icon {
   font-size: $text-l;
}

.admin-menu-version {
   font-size: $text-s;
}

.customer-table-content {
   // height: calc(100vh - 96px - 70px);
   height: 36rem;
   margin: 0 0 $spacing-m 0;
   overflow-y: scroll;
   background-color: $input-background;
}

.search-container {
   display: grid;
   grid-template-columns: 3fr 2fr 7fr;
   column-gap: $spacing-s;

   .admin-count {
      margin-top: $spacing-s;
      margin-left: $spacing-m;
      font-size: $text-s;
   }
}

.customer-table-header {
   display: grid;
   grid-template-columns: repeat(4, 1fr) 2.5fr 2.5fr repeat(2, 1fr) repeat(3, 0.5fr) 0.45fr;
   column-gap: $spacing-s;
   font-weight: bold;
   font-size: $text-xs;
   padding: $spacing-xxs;
   padding-left: -$spacing-xxs !important;
   background-color: $secondary-color;
   color: $hover-color;
   font-family: $title-font;
   overflow-x: hidden;
}

.status-icons-container {
   text-align: center;
   display: flex;
   gap: $spacing-s;

   .green {
      color: $success-color !important;
   }

   .red {
      color: $error-color !important;
   }

   .grey {
      color: $secondary-color;
   }

   .transparent {
      color: transparent;
   }
}

.customer-row {
   display: grid;
   grid-template-columns: repeat(4, 1fr) 2.5fr 2.5fr repeat(2, 1fr) repeat(3, 0.5fr) 0.3fr;
   $text-xs: 8px;
   font-size: $text-xs;
   color: $hover-color;
   cursor: pointer;
   padding: $spacing-xxs;
   overflow-x: hidden;
   font-family: $title-font;

   &:hover {
      background-color: $hover-color;
      color: $element-color;
   }

   .align-right {
      text-align: end;
   }
}

.customer-btn-row{
   color: $text-color;
   font-family: $title-font;
   font-size: $text-xs;
   font-weight: $font-bold;
   letter-spacing: $letter-spacing;
}

.admin-statistics-header {
   font-size: $text-l;
   font-weight: bold;
   border-bottom: $border-width solid black;
   text-align: start;
   margin-top: $spacing-s;
   margin-bottom: $text-xs;
}

.customer-statistics-row {
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   margin-top: $spacing-s;
}

.customer-statistics-row-item {
   flex: 50% !important
}

.customer-card-info-row {
   display: grid;
   grid-template-columns: 0.4fr 2fr;
   gap: $spacing-s;
   white-space: nowrap;
   margin-bottom: $spacing-s;
   font-size: $text-xs;
}

.customer-card-info-row-long {
   display: grid;
   grid-template-columns: 0.6fr 2fr;
   gap: $spacing-xs;
   white-space: nowrap;
   margin-bottom: $spacing-s;
   font-size: $text-xs;
}

.customer-register-date{
   margin-left: $spacing-sm;
}

.customer-row-white {
   background-color: white;
}

.customer-red-btn{
   background-color: $error-color !important;
}

.customer-row-grey {
   background-color: $dark-gray;
}

.customer-container {
   display: flex;
   flex-direction: column;
   gap: $spacing-m;
   padding: $spacing-m;
}


.main-title {
   font-size: $text-l;
   font-weight: bold;
}

.main-subtitle {
   font-size: $text-m;
}

// @media only screen and (max-width: 576px) {

//    hr {
//       width: 75% !important;
//    }
// }

.customer-report-card {

   .report-title {
      font-size: $text-l;
      font-weight: bold;
   }

   .report-subtitle {
      font-size: $text-m;
   }

   // @media only screen and (max-width: 576px) {
   //    .report-login-info {
   //       text-align: center;
   //    }

   //    hr {
   //       width: 75% !important;
   //    }
   // }
}

// @media only screen and (max-width: 576px) {

//    .customer-project-card-container {
//       display: none;
//    }

//    .customer-other-calc-card {
//       display: none;
//    }

// }

.project-table {
   display: flex;
   flex-direction: column;

   .project-table-grid {
      display: grid;
      //   grid-template-columns: 
      //   1fr 1fr 2.5fr 2fr;
      grid-template-columns:
         1.5fr 1.5fr 2.5fr;
      text-align: center;
      gap: $spacing-m;
      margin-right: $spacing-m;
      margin-left: $spacing-m;
   }

   .project-table-header-row {
      align-items: center;
      background-color: $secondary-color;
      height: $row-height;
      font-family: $report-font;
      color: $hover-color;
      font-size: $text-s;
      font-weight: bold;
      cursor: default;
   }

   .project-table-data-container {
      overflow-y: scroll;
   }

   .project-table-data-row {
      align-items: center;
      background-color: $secondary-color;
      height: $row-height;
      font-family: $report-font;
      color: $hover-color;
      font-size: $text-s;
      cursor: pointer;
      justify-content: center;
   }

   .text-align-start {
      text-align: start;
   }

   .text-align-end {
      text-align: end;
   }

   .text-trim {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   }

   .project-table-data-row:hover {
      background-color: $hover-color !important;
      color: $element-color;
   }

   .white-row {
      background-color: $input-background !important;
   }

   .grey-row {
      background-color: $secondary-color !important;
   }
}

.customer-projects-row {
   display: grid;
   grid-template-columns: 1.5fr 1fr;
   gap: $spacing-l;
}

.customer-projects-card {
   display: flex;
   flex-direction: column;
   padding: $spacing-xs 0 $spacing-m 0;
   margin-bottom: $spacing-l;
   height: 25rem;
}

.customer-filter-search-group {
   display: flex;
   gap: $spacing-l;
   padding: 0 $spacing-m;

   .filter {
      display: flex;
      align-items: center;
      gap: $spacing-l;
   }
}

.customer-shared-list {

}

.customer-shared-list-content {
   display: flex;
   flex-direction: column;
   gap: $spacing-xs;
   padding: $spacing-m;
   overflow-y: auto;
   background-color: white;
   height: 17rem;
}

.customer-shared-list-content-entry {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.customer-shared-list-button {
   background-color: $accent-color;
   border: 0 !important;
   font-family: $title-font;
   color: white;
   padding-left: $spacing-s;
   padding-right: $spacing-s;
   border-radius: $site-border-radius;
}

.customer-shared-list-button-deactive {
   border: 0 !important;
   font-family: $title-font;
   color: white;
   padding-left: $spacing-s;
   padding-right: $spacing-s;
   border-radius: $site-border-radius;
   background-color: $dark-gray !important;
}

.customer-shared-list-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   background-color: $primary-color;
   color: $input-background;
   padding: $spacing-xs;
   padding-left: $spacing-m;
   padding-right: $spacing-m;
   font-family: $title-font;
}


.messagebox {
   display: flex;
   flex-direction: row;
   //border-radius: 5px;
   //background-color:$primary-color;
   padding-left: $spacing-xl;
   padding-right: $spacing-xl;
   //box-shadow: 0px 3px 0px $accent-color;

   .messagebox-text {
      color: $text-color;
      font-size: $text-m;
      font-family: $title-font;
      //text-align: center;
      //padding-top: 20px;
      flex: 80%;
   }

   .messagebox-number-zero {
      color: $accent-color;
      font-size: $text-l;
      padding-left: $spacing-xs;
   }

   .messagebox-number-nonzero {
      color: $error-color;
      font-size: $text-l;
      padding-left: $spacing-xs;
   }

   .messagebox-btn {
      background-color: $accent-color;
      color: $element-color;
      font-size: $text-m;
      font-family: $title-font;
      text-align: center;
      border-radius: $site-border-radius;
      padding-top: $spacing-xs;
      padding-bottom: $spacing-xs;
      //margin-top: 20px;
      margin-bottom: 10px;
      text-decoration: none !important;
      flex: 20%;
   }

   .messagebox-btn:hover {
      background-color: $hover-color;
   }
}

// @media only screen and (min-width: 576px) {

//    .admin-sidebar-btn {
//       display: none !important;

//    }

//    .admin-sidebar-close-btn {
//       display: none !important;
//    }
// }

.admin-sidebar-close-btn {
   text-align: end;
}

// @media only screen and (max-width: 576px) {

//    .graph_frame {
//       display: none;
//    }
// }

.graph_frame {
   background-color: $light-gray;
   margin-left: $spacing-xxs;
   margin-right: $spacing-xxs;
   border-radius: $site-border-radius;
   padding: $spacing-xs;
   padding-right: $spacing-l !important;
}

.admin-card-frame {

   border-radius: $site-border-radius;
   background-color: $primary-color;
   align-items: center;
   justify-content: center;
   align-items: center;
   padding: $spacing-m;
   display: flex;
   flex-direction: column;
   gap: $spacing-s;
   font-family: $title-font;
   color: $element-color;
   text-align: center;
   letter-spacing: $letter-spacing;
   margin-bottom: $spacing-m;

   .admin-card-title {
      font-size: $text-m;
   }

   .admin-card-main-number {
      font-size: $text-l;
      font-weight: $font-bold;
   }
}

.ssb-container {
   height: 20rem;
   overflow-y: scroll;
   margin: $spacing-m 0 $spacing-l 0;
}

.message-title-row {
   display: grid;
   grid-template-columns: 1fr 1fr 4fr 0.7fr;
   column-gap: $spacing-s;
   padding-left: $spacing-l;
   padding-right: $spacing-l;
   font-size: smaller;
}

.message-row {
   display: grid;
   grid-template-columns: 1fr 1fr 4fr 0.7fr;
   column-gap: $spacing-s;
   padding-top: $spacing-s;
   padding-bottom: $spacing-s;
   margin-left: $spacing-l;
   margin-right: $spacing-xl;
   font-size: small;
   border-top: solid $border-width $light-gray;
}


.message-button {
   background-color: $primary-color;
   color: $element-color;
   border-radius: $site-border-radius;
   max-width: 6rem;
   text-align: center;
   align-items: center;
   padding: $spacing-xxs;
   font-size: $text-xs;
   font-family: $title-font;

   &:hover {
      background-color: $hover-color;
      cursor: pointer;
   }

}

#message-button-archive {
   background-color: $secondary-color !important;
   color: $hover-color;

   &:hover {
      background-color: $hover-color !important;
      color: $element-color;
   }
}

.message-over {
   overflow: hidden;
}

.message-icon {
   font-size: $text-m;
}

.subscription-title-row {
   display: grid;
   grid-template-columns: 1fr 1fr 0.5fr 0.5fr;
   column-gap: $spacing-s;
   padding: 0 $spacing-s;
   font-size: smaller;
}

.subscription-row {
   display: grid;
   grid-template-columns: 1fr 1fr 0.5fr 0.5fr;
   column-gap: $spacing-s;
   padding: $spacing-s;
   font-size: small;
   border-top: solid 1px $primary-color;
}


.customer-share-container {
   height: 20rem;
   overflow-y: auto;
   margin-top: $spacing-m;
   padding-right: $spacing-m;
}

.customer-no-search-result {
   margin-top: $spacing-m;
   height: 20rem;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: $text-xl;
   color: $secondary-text-color;
   background-color: $element-color;
   font-family: $title-font;
}

.customer-result-row {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   font-size: $text-m;
   color: $hover-color;
   cursor: pointer;
   padding: $spacing-xs;
   overflow-x: hidden;
   font-family: $title-font;
   padding-left: $spacing-m;

   &:hover {
      background-color: $hover-color;
      color: $element-color;
   }

   .align-right {
      text-align: end;
   }

   .align-center {
      text-align: center;
   }
}

.customer-share-row {
   display: grid;
   grid-template-columns: repeat(2, 1fr) 1.5fr 0.2fr;
   font-size: $text-s;
   color: $hover-color;
   cursor: pointer;
   padding: $spacing-xs;
   overflow-x: hidden;
   font-family: $title-font;
   padding-left: $spacing-m;
   align-items: center;
   gap: $spacing-m;

   &:hover {
      background-color: $hover-color;
      color: $element-color;
   }

   .align-right {
      text-align: end;
   }

   .align-center {
      text-align: center;
   }
}

.customer-share-row-selected {
   background-color: $hover-color;
   color: $element-color;
}

.index-row {
   background-color: $primary-color;
   color: $element-color;
   font-family: $title-font;
   font-size: $text-l;
   font-weight: bold;
}

.index-data-row {
   background-color: $light-gray;
   color: $hover-color;
   font-family: $title-font;
   font-size: $text-m;
}

.index-btn {
   background-color: $primary-color;
   color: $element-color;
   font-family: $title-font;
   font-size: $text-xs;
   text-align: center;
   border-radius: $site-border-radius;
   width: 4rem;


   &:hover {
      background-color: $hover-color;
      cursor: pointer;
   }
}

.admin-checkbox {
   font-size: $text-m;
}

.admin-checkbox-box {
   color: $accent-color !important;

   &:hover {
      background-color: transparent !important;
   }
}

.admin-checkbox-text {
   padding-top: $spacing-l;
}

.admin-title {
   font-size: $text-l;
}


.links-container {
   display: flex;
   flex-direction: column;
   padding: $spacing-m $spacing-s;


   .link-row {
      font-family: $title-font;
      font-size: $text-m;
      display: flex;
      align-items: center;
      gap: $spacing-m
   }
}

.update-message-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: $spacing-xl;

   .update-message-row {
      width: 100%;
      display: flex;
      gap: $spacing-xl;
      align-items: center;
      font-family: $report-font;
      font-size: $text-m;
      border-bottom: $border-width solid $primary-color;
      height: auto;
      padding: $spacing-m;
   }
}

.customer-message {
   font-size: $text-s;
   font-family: $title-font;
   margin-bottom: $spacing-sm;
}

.customer-message-container {
   display: flex;
   flex-direction: column;
}

.customer-message-button-container {
   display: flex;
   column-gap: $spacing-s;
}



// @media only screen and (min-width: 576px) {
//    .message-row-message {
//       hr {
//          display: none;
//       }
//    }
// }

// @media only screen and (max-width: 576px) {
//    .message-mobile {
//       display: none;
//    }

//    .message-mobile-padding {
//       padding-top: $spacing-m !important;
//    }

//    .admin-checkbox {
//       padding: 0;
//    }


// }