.picture-module-picture-1-col{
  height: 28.5vw;
  max-height:600px;
  padding-bottom: 10px;
}

.picture-module-picture-2-cols{
  height: 23.5vw;
  max-height:400px;
  padding-bottom: 10px;
}

.picture-module-picture-3-cols{
  height: 15vw;
  max-height:300px;
  padding-bottom: 10px;
}

