.bk-project-header-tile-line {
  flex-wrap: wrap;
}

.bk-project-header-tile {
  background-color: $input-background;
  height: 14rem;
  padding: $spacing-xs $spacing-m;
  display: grid;
  grid-template-rows: 1fr 4fr 1fr;
  font-family: $title-font;
  min-width: 10rem;
}

.bk-project-tile-header {
  color: $primary-color;
  text-transform: uppercase;
  font-weight: $font-bold;
  font-family: $title-font;
  font-size: $text-m;
}

.bk-project-tile-body {
  overflow-y: auto;
}

.bk-project-tile-picture-frame {
  height: 10rem;
  width: 100%;
}
.bk-project-tile-picture {
  object-fit: scale-down;
  height: 100%;
  width: 100%;
  overflow: hidden;
}



.title-row {
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: $spacing-xl;

  .title-btn-group {
    display: flex;
    align-items: center;
    gap: $spacing-m;
  }

  .filter-search-group {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: $spacing-m;

    .filter {
      display: flex;
      align-items: center;
      gap: $spacing-m;
    }
  }
}

.notes {
  white-space: pre-line;
  overflow-x: hidden;
}

.main-table-header-row {
  align-items: center;
  background-color: $secondary-color;

  font-family: $title-font;
  color: $text-color;
  font-size: $text-xs;
  letter-spacing: $letter-spacing;
  font-weight: bold;
  cursor: default;
  padding: $spacing-xxs 0;
  white-space: nowrap;
}

.main-table-header-row-other-user {
  background-color: $warning-color
}

.main-table-data-container {
  max-height: 78vh;
  overflow-y: scroll;
  margin-bottom: $spacing-m;
}

.main-table-data-row {
  align-items: center;
  background-color: $secondary-color;
  height: $row-height;
  color: $text-color;
  font-family: $title-font;
  font-size: $text-xs;
  font-weight: $font-bold;
  letter-spacing: $letter-spacing;
  cursor: pointer;
}

.small-table-data-row {
  align-items: center;
  background-color: $secondary-color;
  height: $row-height-small;
  color: $text-color;
  font-family: $title-font;
  font-size: $text-xs;
  font-weight: $font-bold;
  letter-spacing: $letter-spacing;
  cursor: pointer;
}

.text-trim {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-table-data-row:hover {
  background-color: $hover-color !important;
  color: $element-color;
}

#main-table-data-row-no-hover:hover {
  background-color: none !important
}

.project-picture {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  height: $row-height !important;

  img {
    width: 100% !important;

  }
}

.status-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.white-row {
  background-color: $input-background !important;
}

.grey-row {
  background-color: $secondary-color !important;
}


.project-row-header {
  margin-bottom: $spacing-s;
}

.bk-row-orange {
  display: flex;
  background-color: #e1c4ad;
  width: 100%;
  height: $row-height;
  font-size: $text-s;
  font-weight: bold;
  padding-left: $spacing-l;
  padding-right: 0px;
}

.bk-row-blue {
  display: flex;
  background-color: $secondary-color;
  width: 100%;
  height: $row-height;
  font-size: $text-xs;
  font-weight: bold;
  border-top: $border-width solid #CBCBCB;
  padding-left: $spacing-l;
  padding-right: 0px;
}

#bk-small-project-row {
  padding-left: $spacing-m;
  background-color: $primary-color;
  color: $element-color;
  border-radius: $site-border-radius;
}

.bk-row-contact {
  display: flex;
  background-color: $secondary-color;
  width: 100%;
  height: $row-height;
  font-size: $text-s;
  font-weight: bold;
}

.bk-row-grey {
  display: flex;
  background-color: $dark-gray;
  width: 100%;
  padding-left: $spacing-l;
  padding-right: 0px;
}

.bk-row-white {
  display: flex;
  background-color: $input-background;
  width: 100%;
  padding-left: $spacing-l;
  padding-right: 0px;
}

.bk-project-row-flex-min {
  flex: 0.5;
}

.bk-project-row-flex-075 {
  flex: 0.75;
}


.bk-project-row-flex-1 {
  flex: 1;
}

.bk-project-row-flex-1-5 {
  flex: 1.5;
}

.bk-project-row-flex-2 {
  flex: 2;
}

.bk-project-row-flex-3 {
  flex: 3;
}

.bk-project-row-flex-4 {
  flex: 4;
}

.bk-project-row-flex-5 {
  flex: 5;
}

.bk-project-row-flex-6 {
  flex: 6;
}

.bk-project-row {
  color: #31363A;
  font-size: 15px;
  height: $row-height;
  font-weight: 500;
  cursor: pointer;

  .bk-project-text-trim {
    white-space: nowrap;
    overflow: hidden;
  }
}

.bk-project-row:hover {
  background-color: $hover-color !important;
  color: $element-color !important;
}

.bk-project-row-text {
  font-size: $text-xs;
  font-family: $title-font;
}

.bk-project-row-format {
  margin-left: $spacing-s;
  white-space: nowrap;
  overflow: hidden
}



.bk-project-button-margin-right {
  margin-right: $spacing-l;
}

.bk-project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.bk-project-image-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.bk-project-image-background img {
  width: 100%;
  height: 100%;
  //filter: blur(8px);
  object-fit: cover;
  overflow: hidden;
  //height: 22rem;
  opacity: .8;

}

.bk-project-icon {
  float: right;
  width: 40px;
  padding-right: $spacing-s;
  cursor: pointer;
}

.bk-project-header {
  width: 100%;
  height: 300px;
  /*57vh*/
  //background: linear-gradient(90deg, rgba(106,157,158,1) 0, rgba(135,176,177,1) 50%, rgba(212,228,228,1) 100%);
  background: transparent;
  //padding: 10px;
}


.bk-project-header-tiles {
  width: 100%;
  height: 230px;
  display: flex;
}



.bk-project-header-tile-image-container {
  flex: 12;
  height: 230px;
  background-color: white;
  // margin-left: 10px;
  //margin-right: 20px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.bk-project-image-div {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.transparent {
  background-color: transparent;
}



.bk-project-header-tile-top {
  color: $primary-color;
  text-transform: uppercase;
  font-size: 0.8vw;
  font-weight: bold;
  margin-top: 0.3vw;
  margin-left: 1vw;
  font-family: $title-font;
  font-size: $text-m;
  //margin-left: 1vw;
  margin-bottom: 0.5vw;
}

.bk-project-header-tile-text-frame {
  text-align: left;
  margin-bottom: 0.2vw;
  display: flex;
  margin-left: 1vw;
  margin-right: 0.5vw;

  .notes {
    white-space: pre-line;
  }
}

.bk-project-header-tile-text-header {
  color: #334652;
  font-size: 0.7vw;
  font-weight: bold;
  //  margin-left: 1vw;
  flex: 4;
}

.bk-project-header-tile-text-value {
  color: #334652;
  font-size: $text-xs;
  // font-weight: bold;
  //margin-left: 1vw;
  flex: 8;
  overflow: hidden;
  white-space: nowrap;

  //margin-right:1vh;
  //padding-right:1vh;
  span {
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }
}


.bk-project-header-tile-text-box {
  color: #334652;
  font-size: $text-xs;
  white-space: initial;
  flex: 12;
  display: flex;
  max-height: 160px;
  overflow: hidden;

  div {
    flex: 1;
  }

}



.bk-project-header-tile-text {
  color: #334652;
  font-size: $text-xs;
  // font-weight: bold;
  //margin-left: 1vw;
  flex: 12;
  display: flex;

  div {
    flex: 1;
  }
}

.bk-project-header-tile-property-container {
  font-size: $text-xs;
  //margin-left: 1vw;
  color: #334652;
}

.bk-project-header-tile-property {
  width: 5vw;
  float: left;
}


.bk-project-header-tile-bottom {
  width: 100%;
  height: 3vw;
  position: absolute;
  bottom: 0;
  padding-left: 1vw;
  display: flex;
}

.bk-project-header-tile-bottom-date {
  flex: 4;
  color: #334652;
  font-size: 0.7vw;
  font-weight: bold;
  padding-bottom: 0.2vw;
  margin-top: 1.4vw;
}


.bk-project-header-tile-bottom-text {
  flex: 4;
  color: #334652;
  font-size: 0.7vw;
  font-weight: bold;
  align-self: flex-end;
  padding-bottom: 0.5vw;
}

.bk-project-header-tile-bottom-color {
  flex: 4;
  align-self: flex-end;
  padding-bottom: 0.2vw;
}

.bk-project-header-tile-bottom-edit {
  flex: 2;
  align-self: flex-end;
  padding-bottom: 0.2vw;
}

.bk-project-image-header-tile-bottom-edit {
  position: absolute;
  //background-color: white;
  bottom: 0;
  z-index: 500;
  right: 0;
  padding-bottom: 0.2vw;
}

.bk-triangle-bottomright {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: 76px solid white;
  border-left: 76px solid transparent;
  opacity: 0.4;
  z-index: 500;
}


.bk-header-icon-edit {
  float: right;
  width: 1.8vw;
  padding-right: 0.2vw;
  cursor: pointer;
}

.bk-project-row-grey {
  background-color: $dark-gray;
  color: white;
  width: 100%;
  height: 2vw;
  padding-top: 0.5vw;
  padding-left: 3vw;
  font-size: 0.9vw;
  font-weight: bold;
}

.bk-project-row-white {
  background-color: $input-background;
  color: $input-background;
  width: 100%;
  height: 2vw;
  padding-top: 0.5vw;
  padding-left: 3vw;
  font-size: 0.9vw;
  font-weight: bold;
}

.bk-project-row-blue {
  background-color: $secondary-color;
  color: $input-background;
  width: 100%;
  height: 2vw;
  padding-top: 0.5vw;
  padding-left: 3vw;
  font-size: 0.9vw;
  font-weight: bold;
}

.bk-project-row-green {
  background-color: $accent-color;
  color: $input-background;
  width: 100%;
  height: 2vw;
  padding-top: 0.5vw;
  padding-left: 3vw;
  font-size: 0.9vw;
  font-weight: bold;
}

.bk-project-row-align-left {
  text-align: left;
}

.bk-project-row-align-right {
  text-align: right;
}

.bk-project-row-align-center {
  text-align: center;
}

.add-margin {
  margin-top: 0.6vw;
}

.project-dialog-field-row {
  margin-bottom: $spacing-m;
}


.project-dialog-edit-wrapper {
  display: flex;
  max-width: 27rem;
}

.project-dialog-edit-frame {
  flex: 1;
  display: block;
  padding: 0 20px;

  div {
    margin-right: $spacing-s;
  }
}

.large-field {
  max-width: 26rem;
}

.county-field {
  padding-top: $spacing-xs;
  min-width: 12.5rem;
}

.small-field {
  max-width: 7.5rem;
}

.bk-projects-no-image {
  width: 100%;
  height: 100%;
  /*background: 
         linear-gradient(to top left,
             rgba(170,170,170,0) 0%,
             rgba(170,170,170,0) calc(50% - 0.8px),
             rgba(170,170,170,1) 50%,
             rgba(170,170,170,0) calc(50% + 0.8px),
             rgba(170,170,170,0) 100%),
         linear-gradient(to top right,
             rgba(170,170,170,0) 0%,
             rgba(170,170,170,0) calc(50% - 0.8px),
             rgba(170,170,170,1) 50%,
             rgba(170,170,170,0) calc(50% + 0.8px),
             rgba(170,170,170,0) 100%);*/
  padding-top: 40%;
  font-family: $title-font;
  font-size: $text-l;
  color: $primary-color;
  font-weight: bold;
}

.bk-project-header-button-row {
  display: flex;
}

.bk-project-header-button {
  background-color: #D1E0E1;
  color: #334652;
  font-size: 0.8vw;
  font-weight: bold;
  margin-top: 0.5vw;
  //margin-left: 0.5vw;
  margin-right: 1vw;
  padding-left: 1.3vw;
  padding-right: 1.3vw;
  padding-top: 0.4vw;
  height: 2vw;
  min-width: 8vw;
  cursor: pointer;
  border: 1px solid white;
}


.bk-project-status {
  flex: 5;
  display: flex;
  flex-direction: row-reverse;
  margin-right: 2vw;
  padding-top: 8px;
  // display:flex;
  // float:right;
  // //border:1px solid green;
  // margin-right:3vw;
  // padding-top:0.5vw;
  // cursor:pointer;
}

.bk-project-status-text {
  flex: 5;
  margin-right: 1vw;
  color: #334652;
  font-size: 0.8vw;
  font-weight: bold;
  margin-top: 0.35vw;
}

.bk-project-printer-icon {
  margin-top: 7px;
  margin-right: 10px;
}


.bk-header-icon-printer {
  height: 45px;
  width: 45px;
  cursor: pointer;
  margin-bottom: 7px;
}

.bk-calculation-project-link {}

.bk-header-icon-printer-report {
  height: 45px;
  width: 45px;
  margin-bottom: 8px;
  cursor: pointer;
}

.aligned {
  justify-self: end !important;
  justify-content: end !important;
  justify-items: end !important;
  text-align: end !important;
}


.bk-header-project-btn {
  margin-top: 21px;
}

.bk-project-status-icon {
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  border-color: $primary-color;
  cursor: pointer;
  background-color: $input-background;
}

.bk-project-icon-cell {
  margin-left: 30px;
  margin-top: 5px;
}

.bk-project-status-active {
  background-color: #26AF66;
}

.bk-project-status-closed {
  background-color: #F3C93A;
}

.bk-project-status-deleted {
  background-color: #DD392C;
}


.bk-upload-frame {
  width: 100%;
  height: 60%;
  margin: 2vw;
  border: 4px dashed rgba(106, 157, 158, 1);
  border-radius: 10px;
  cursor: pointer;
}



.bk-upload-frame-header {
  font-size: 22px;
  font-weight: bold;
  margin-top: 1vw;
  color: rgba(106, 157, 158, 1);
}




.bk-project-row-image {
  padding-top: 1px;

  div {

    img {
      //  width:auto !important;
      //  height:36px !important;
    }
  }
}




.bk-upload-frame-2 {
  width: 100%;
  height: 100%;
  background:
    linear-gradient(to top left,
      rgba(170, 170, 170, 0) 0%,
      rgba(170, 170, 170, 0) calc(50% - 0.8px),
      rgba(170, 170, 170, 1) 50%,
      rgba(170, 170, 170, 0) calc(50% + 0.8px),
      rgba(170, 170, 170, 0) 100%),
    linear-gradient(to top right,
      rgba(170, 170, 170, 0) 0%,
      rgba(170, 170, 170, 0) calc(50% - 0.8px),
      rgba(170, 170, 170, 1) 50%,
      rgba(170, 170, 170, 0) calc(50% + 0.8px),
      rgba(170, 170, 170, 0) 100%);
}

.bk-border-top {
  border-top: 1px solid black;
}

.new-service-container {
  display: flex;
  min-height: 50vh;
  max-height: 50vh;




  .new-service-button {
    //max-width: 12vw;      
    background-color: $light-gray;
    color: $text-color;
    border-radius: $site-border-radius;
    font-size: 0.8vw;
    font-weight: bold;
    margin-top: 0.5vw;
    margin-right: 0;
    padding-left: 0.8vw;
    padding-right: 0.8vw;
    padding-top: 0.4vw;
    height: 2vw;
    min-width: 8vw;
    cursor: pointer;

    &:hover {
      background-color: $hover-color;
      color: $element-color;
    }
  }

  .new-service-button-selected {
    background-color: $hover-color;
    color: $element-color;
  }


  .selectEmpty {
    margin-top: 2px;
  }

}

.bk-report-title {
  display: flex;
  flex-direction: column;
}

.bk-project-owner-title {
  color: $error-color;
  font-family: $title-font;
  font-size: $text-m;
  margin-left: $spacing-sm;
}

.bk-project-status-title {
  color: $primary-color;
  font-family: $title-font;
  font-size: $text-l;
  font-weight: $font-bold;
  letter-spacing: $letter-spacing;
}

.bk-project-status-button {
  background-color: $accent-color;
  padding-top: 0.2px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: $hover-color;
  }
}


.projects-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.project-note-dialog {
  min-width: 300px;
}