
.statistics-box {
   width: 100%;
   margin-bottom: $spacing-m;
}

.statistics-header-row {
   align-items: center;
   background-color: $secondary-color;
   font-family: $title-font;
   color: $text-color;
   font-size: $text-xs;
   letter-spacing: $letter-spacing;
   font-weight: bold;
   cursor: default;
   padding: $spacing-xxs $spacing-s ;
}

.statistics-data-row {
   align-items: center;
   color: $text-color;
   font-family: $title-font;
   font-size: $text-xs;
   font-weight: $font-bold;
   letter-spacing: $letter-spacing;
   cursor: pointer;
   padding: $spacing-xxs $spacing-s ;
}

.statistics-sum-row {
   background-color: $primary-color;
   color: $element-color;
   font-weight: $font-bold;
}