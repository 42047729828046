@import 'src/assets/style/scss/base/variables.scss';

.navbarbutton {
    font-size: $text-m;
    color: $primary-color;
    font-family: $title-font;
  
    &:hover {
      color:$hover-color;
    }
  
  }

  // @media only screen and (max-width: 768px) { 
  //   .navbarbutton{
  //     color: #e6e7e8;
  //     text-align: center;
  //     font-size: 25px;
  //   }

    
      
  // }