.phrase-menu {
   flex: 0.5;
   font-size: $text-xl;
   padding-top: $spacing-xs;
   cursor: pointer;
}

.building-description-category-frame {
   padding-left: $spacing-s;
   padding-right: $spacing-s;
   margin-top: $spacing-s;
}

.building-description-category-section {
   margin-top: $spacing-s;
   margin-bottom: 20px;

   .title {
      font-size: $text-l;
      margin-left: 80px;
      color: black;
      margin-bottom: $spacing-xxs;
      font-weight: bold;
   }

   .text {
      padding: $spacing-xs;
      padding-left: $spacing-s;
      resize: vertical;
      min-height: 2em;
      overflow: auto;
   }

   .text-frame {
      display: flex;

      .category-menu {
         flex: 0.5;
         font-size: $text-l;
         padding-top: $spacing-xs;
         cursor: pointer;
      }
   }

}

.action-title {
   font-size: $text-l;
   margin-left: 30px;
   color: black;
   margin-top: 4px;
}







.building-description-assessment-section {
   margin-top: 10px;
   margin-bottom: 20px;

   .assessment-title {
      font-size: $text-l;
      font-weight: normal;
      margin-left: 150px;
      color: black;
      input {
         font-size: $text-m;
      }
   }

   .assessment-instruction-icon {
      color: $dark-gray;
      cursor: pointer;
      font-size: $text-l;
      margin-left: 7px;
   }

   .optional-assessment {
      padding: $spacing-xs !important;
      margin-bottom: $spacing-s;
      width: 30%;
   }

   .assesment-grid-definition {
      display: grid;
      grid-template-columns: 0.5fr 5fr;
      justify-content: center;
      padding-left: $spacing-s;

      
   }

   .assessment-input-field {
      font-size: $text-l !important;
      padding: $spacing-xs !important;
   }

   .action-grid-definition {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10rem;

      .action-input{
         margin-left: $spacing-xl;
      }
   }

   .assessment-action-title {
      font-size: $text-l;
      color: black;
      margin-top: $spacing-s;
      margin-bottom: $spacing-xs;
      font-weight: bold;
   }

   .assesment-estimatedCost-grid-definition{
      display: grid;
      grid-template-columns: 1.3fr 5fr;
      align-content: center;
      gap: $spacing-s;
      margin-left: 10rem;
   }

   .input-frame {
      position: relative;
      margin-bottom:$spacing-m;
      margin-left: $spacing-xl;

      i {
         left: calc(95px); 
         position: absolute;
         transform: translate(0, -50%);
         top: 50%;
         width: 25px;
         text-align: center;
         font-style: normal;
       }
   }
   
   .instruction-text {
      font-size: $text-m;
      margin-right: $spacing-xl;
      margin-bottom: $spacing-m;
   }


   //    .assessment-margin{
   //       margin-left: 31px
   //    }

   //    .assessment-margin-miner{
   //       margin-left: 137px
   //    }

   //    .assessment-estimated-cost-frame{
   //       display: flex;
   //    }


   //    .assessment-frame {
   //       display:flex;

   //       .blank-placeholder {
   //          flex:2;
   //       }



   //       .assessment-text {
   //          flex:18;

   //          .autotext {
   //             font-size:$text-l !important;
   //             padding:$spacing-xs !important;
   //          }
   //       }

   //      


   //       }

   //       .assessment-action-text-input{
   //          flex:14;

   //         
   //         
   //       }



   //       .assessment-menu {
   //          flex:0.5;
   //          font-size:$text-l;
   //          padding-top:4px;
   //          cursor:pointer;
   //       }


   //    }
}


.tg-text {
   font-weight: bold;
   font-size: $text-m;
}


.building-description-category-picture-container {
   display: flex;
   flex-wrap: wrap;
}

.building-description-category-picture {
   //flex:4;
   width: 14vw;
   margin-right: $text-l;

   .image-frame {
      max-height: 100%;
      cursor: pointer;
      text-align: right;
      border: $border-width solid $dark-gray;
      border-radius: $site-border-radius;

      img {
         height: 14vw;
         width: 100%;
         object-fit: contain;
         vertical-align: top;
      }

      .no-image2 {
         // max-width: 54px;
         height: 6vw;
         background:
            linear-gradient(to top left,
               rgba(170, 170, 170, 0) 0%,
               rgba(170, 170, 170, 0) calc(50% - 0.8px),
               rgba(170, 170, 170, 1) 50%,
               rgba(170, 170, 170, 0) calc(50% + 0.8px),
               rgba(170, 170, 170, 0) 100%),
            linear-gradient(to top right,
               rgba(170, 170, 170, 0) 0%,
               rgba(170, 170, 170, 0) calc(50% - 0.8px),
               rgba(170, 170, 170, 1) 50%,
               rgba(170, 170, 170, 0) calc(50% + 0.8px),
               rgba(170, 170, 170, 0) 100%);
         padding-top: 2vw;
         text-align: center;
         font-size: 24px;
      }

   }

   .text {
      margin-top: $spacing-xs;
      margin-left: 0 !important;
      margin-right: 0 !important;
      width: 100% !important;
   }
}

.hidden {
   display: none
}

.building-description-checklist-section {
   margin-top: $spacing-s;
   margin-left: $spacing-s;

   .checklist-frame {
      display: flex;


   }

   .checklist-title {
      font-size: $text-s;
      font-weight: bold;
      margin-left: $spacing-m;
      color: black;
   }

   .checkpoint-group-title {
      flex: 18.5;
      font-size: $text-s;
      font-weight: bold;
      color: black;
      margin-left: $spacing-s;
      margin-right: $spacing-s;
   }

   .checklist-header-content {
      flex: 18;
      background-color: #f8f8f8;
      display: flex;
      font-size: $text-m;
      font-weight: bold;
      ;
      border-top: $border-width solid #aaa;
      border-left: $border-width solid #aaa;
      margin-right: $spacing-s;
   }

   .checklist-content {
      flex: 18;
      background-color: #f8f8f8;
      display: flex;
      font-size: $text-m;
      border-left: $border-width solid #aaa;
      margin-right: $spacing-s;
   }

   .checklist-border {
      padding: $spacing-xs;
      border-bottom: $border-width solid #aaa;
      border-right: $border-width solid #aaa;
   }


   .checklist-number {
      flex: 0.5;
      text-align: center;
   }

   .checklist-name {
      flex: 10;
   }

   .checklist-option-long {
      flex: 3;
      text-align: center;
   }

   .checklist-comment {
      flex: 6;
   }

   .checklist-name-short {
      flex: 7;
   }

   .checklist-option {
      flex: 2;
      text-align: center;
   }

   .checklist-notes {
      flex: 6;
   }

   .checklist-comment-short {
      flex: 5;
   }

   .category-menu {
      flex: 0.5;
      font-size: $text-l;
      padding-top: $spacing-xs;
      cursor: pointer;
   }

   .tg-placeholder {
      flex: 1;
      margin-right: $spacing-xxs;
      margin-bottom: $spacing-xxs;
   }




   .mini-text {
      padding: $spacing-xs;
      padding-left: $spacing-s;
      width: 100%;
      min-height: 2em;
      text-align: center;
   }

   .text {
      margin: -4px !important;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border: 0;
   }

}


.new-category-dialog-frame {
   max-height: 60vh;
   width: 30vw;
   margin-bottom: $spacing-m;
}

.new-category-dialog-row {
   display: flex;
   margin-bottom: $spacing-xxs;
}

.new-category-dialog-button {
   flex: 2;
   border: $border-width solid #ccc;
   text-align: center;
   font-weight: bold;
   padding-top: $spacing-xs;
   margin-right: $spacing-s;
   cursor: pointer;
}

.new-category-dialog-button:hover {
   background-color: $primary-color;
}

.building-description-text-input {
   display: flex;
}

.building-description-text {
   flex: 18.5;

   .description-text {
      padding-left: $spacing-m;

      .padding-6 {
         padding: $spacing-xs !important;
      }
   }


}

.building-description-simple-container {
   margin-bottom: $spacing-m;
}