.contact-dialog-frame {
   position: fixed;
   right:2vw;
   bottom: 5vh;
   z-index: 999;
}

#dialog-hidden {
   display: none;
}

.contact-dialog-collapsed {
   float: right;
   height: $spacing-xl;
   width: $spacing-xl;
   background-color: $primary-color;
   border-radius: 50%;
   cursor: pointer;
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;

   i {
      color: white;
      font-size: $text-xl;
   }

   &:hover {
      background-color: $hover-color;
    }
}

.contact-dialog-box {
   position: fixed;
   right:2vw;
   bottom: 13vh;
   z-index: 999;
   width:320px;
   border-radius: $site-border-radius;
   background-color: $input-background;
   transition: visibility 0s, opacity 0.5s linear;
   outline: none;
}


.contact-dialog-box-header{
   color:white;
   background-color: $primary-color;
   border-radius: $site-border-radius $site-border-radius 0 0;
   padding:$spacing-s;
   font-family: $title-font;
   
   i {
      color: white;
      font-size: $text-m;  
   }

   svg:first-child {
      margin-right: $spacing-s;
   }
   svg:last-child {
      margin-top:$spacing-xs;
      float:right;
      cursor:pointer;
   }
}

.contact-dialog-box-content{
   padding: $spacing-m;
   font-family: $title-font;

   div {
      margin-bottom:$spacing-s;
   }
}
.contact-dialog-box-form{
   width:100%;
}

.contact-dialog-date-picker{
   flex:1;
   display: flex;
   margin-left:$spacing-l;
   margin-bottom:$spacing-s;
   align-items:baseline;
   gap: $spacing-s;
}