﻿/* react-toastify NPM PACKAGE CSS OVERRIDES */
.Toastify__toast {
  border-radius: $site-border-radius;
}

/* OVERRIDES & GENERAL STYLES */
* {
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=date]{
  display: flex;
  border-radius: $site-border-radius;
  border: 1px solid $dark-gray;
  background: $input-background;
  font-size: $text-m;
  padding: 0 $spacing-m;
  width: 100%;
}

#root {
  height:100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

html,
body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  font-family: $report-font !important;
  background-color: $background-color !important;
}

.content-frame{
  border-width: $spacing-xxs !important;
  border-top-width: 0 !important;
  border-color: $dark-gray !important;
  border-style: solid !important;
  border-radius: 0 0 $site-border-radius $site-border-radius !important;
  justify-content: center !important;
  padding: $spacing-m;
}

.summary-content{
  background-color: $placeholder-color;
}

.is-horizontal-center {
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper {
  min-height: 60rem;
  padding-bottom: $spacing-xl;

  .card-content {
    padding: $spacing-m $spacing-l;

    .content li+li {
      margin-top: 1em;
    }

    .dashboard-info {
      padding: 1.5em !important;
    }
  }

  hr {
    width: 50%;
    margin: $spacing-s auto;
    background-color: $dark-gray
  }
}

.dashboard-link {
  color: $text-color;
  font-weight: 700;
  padding: 0.25em 0.5em 0.25em;
  margin-right: 0.25em;
  transition: background-color 0.2s ease-out, border-bottom-color 0.2s ease-out;

  &.react {
    background-color: rgba(32, 156, 238, 0.18);
    border-bottom: 1px solid rgba(32, 156, 238, 0.725);

    &:hover {
      background-color: rgba(32, 156, 238, 0.3);
      border-bottom-color: rgba(32, 156, 238, 1);
    }
  }

  &.redux {
    background-color: rgba(118, 74, 188, 0.18);
    border-bottom: 1px solid rgba(118, 74, 188, 0.725);

    &:hover {
      background-color: rgba(118, 74, 188, 0.3);
      border-bottom-color: rgba(118, 74, 188, 1);
    }
  }

  &.aspcore {
    background-color: rgba(118, 74, 188, 0.18);
    border-bottom: 1px solid rgba(118, 74, 188, 0.725);

    &:hover {
      background-color: rgba(118, 74, 188, 0.3);
      border-bottom-color: rgba(118, 74, 188, 1);
    }
  }

  &.sass {
    background-color: rgba(198, 83, 140, 0.18);
    border-bottom: 1px solid rgba(198, 83, 140, 0.725);

    &:hover {
      background-color: rgba(198, 83, 140, 0.3);
      border-bottom-color: rgba(198, 83, 140, 1);
    }
  }

  &.typescript {
    background-color: rgba(41, 78, 128, 0.18);
    border-bottom: 1px solid rgba(41, 78, 128, 0.725);

    &:hover {
      background-color: rgba(41, 78, 128, 0.3);
      border-bottom-color: rgba(41, 78, 128, 1);
    }
  }
}


.clear-both {
  clear: both;
}

.display-none {
  display: none;
}

.display-flex {
  display: flex;
}

.visibility-hidden {
  visibility: hidden
}

.justify-content-center {
  justify-content: center;
}

.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: start;
}
.align-items-end {
  align-items: end;
}

.align-items-top {
  align-items: start !important;
}
.align-items-bottom {
  align-items: bottom;
}

.flex-auto {
  flex:auto;
}

.flex-01 {
  flex:0.1;
}
.flex-015 {
  flex:0.15;
}

.flex-025 {
  flex:0.25;
}


.flex-05 {
  flex: 0.5;
}

.flex-1 {
  flex: 1;
}

.flex-1-25 {
  flex:1.25;
}

.flex-1-5 {
  flex:1.5;
}

.flex-2 {
  flex: 2;
}
.flex-2-5 {
  flex:2.5;
}
.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}
.flex-6\5 {
  flex:6.5;
}

.flex-7 {
  flex: 7;
}

.flex-8 {
  flex: 8;
}

.flex-9 {
  flex: 9;
}

.flex-10 {
  flex: 10;
}

.search-cursor {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}


#form-control-thin-input{
  height: 25px;
  font-size: $text-xs;
  padding: 2px;
  padding-left: 10px;
}

.form-control-large {
  width: 200px !important;
  padding: 6px 6px !important;
}

.form-control-large-text {
  width: 200px !important;
  padding: 6px 6px 6px 10px !important;
}

.form-control-note {
  min-width: 400px !important;
  width: 99% !important;
  padding: 6px 6px 6px 10px !important;
  margin-top: 20px;
  box-shadow: none !important;
}

#form-dialog-title {
  font-family: $title-font;
  background-color: $primary-color;
  color: $light-gray;
}

.form-dialog-btn {
  font-family: $title-font !important;
  color: $element-color !important;
  background-color: $primary-color !important;
  border-radius: 10px !important;
  font-size: $text-m !important;

  &:hover {
    background-color: $hover-color !important;
  }
}

.question-image {
  width: 20px;
  background-color: $light-gray;
  padding: 1px;
  border-radius: 10px;
  margin-left: 20px;
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.text-red {
  color: $error-color;
}

.bold-important {
  font-weight: bold !important;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.justify-center {
 align-items: center;
}
.no-image {
  width: 100%;
  height: 100%;
  background:
    linear-gradient(to top left,
      rgba(170, 170, 170, 0) 0%,
      rgba(170, 170, 170, 0) calc(50% - 0.8px),
      rgba(170, 170, 170, 1) 50%,
      rgba(170, 170, 170, 0) calc(50% + 0.8px),
      rgba(170, 170, 170, 0) 100%),
    linear-gradient(to top right,
      rgba(170, 170, 170, 0) 0%,
      rgba(170, 170, 170, 0) calc(50% - 0.8px),
      rgba(170, 170, 170, 1) 50%,
      rgba(170, 170, 170, 0) calc(50% + 0.8px),
      rgba(170, 170, 170, 0) 100%);
  color: $accent-color;
  text-align: center;
  padding-top: 29%;
  font-size: $text-xl;
}

.bk-icon{
  flex: 0.1;
  font-size: $text-lm;
  cursor: pointer;
  text-align: center;
  min-width: $spacing-m;
}
    
.delete-icon{
  color:$error-color;
}

.red-icon {
  color: $error-color;
}

.printer-icon {
  margin-top:$spacing-s;
  font-size: $text-l;
  color: $primary-color;
}

.tg-button {
  border: $border-width solid rgb(151, 217, 158);
  border-radius: $site-border-radius;
  max-height: 3rem;
  max-width: 5rem;
  padding: $spacing-xs;
  box-shadow: $site-border-radius $site-border-radius white, $site-border-radius $site-border-radius 0 0.09375rem rgb(151, 217, 158);
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  color: $input-background;
  font-family: 'Gothic A1', sans-serif;
  font-size: 1.2rem;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-left: 0.65rem;
}

.tg-button:hover {
  background-color: $hover-color !important;
  box-shadow: $site-border-radius $site-border-radius white, $site-border-radius $site-border-radius 0 0.09375rem $hover-color !important;
  
}

.tg-button-small {
  border: 1.5px solid rgb(151, 217, 158);
  border-radius: $site-border-radius;
  max-height: 3rem;
  max-width: 5rem;
  padding: $spacing-xs;
  box-shadow: $site-border-radius $site-border-radius white, $site-border-radius $site-border-radius 0 0.09375rem rgb(151, 217, 158);
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  color: white;
  font-family: 'Gothic A1', sans-serif;
  font-size: $text-s;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

}

.tg-button-disabled {
  border: $border-width solid rgb(151, 217, 158);
  border-radius: $site-border-radius;
  max-height: 3rem;
  max-width: 5rem;
  padding: $spacing-xs;
  box-shadow: $site-border-radius $site-border-radius white, $site-border-radius $site-border-radius 0 0.09375rem rgb(130, 130, 130);
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  color: $input-background;
  font-family: 'Gothic A1', sans-serif;
  font-size: 1.2rem;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-left: 0.65rem;
  opacity:0.2 !important;
  cursor:default;
}

.tg-row{
  border-bottom: $border-width solid $text-color;
  padding-bottom: $spacing-l;
}

.float-right {
  float:right;
}


.border-single {
  border-bottom: $border-width solid $text-color;
}

.border-double {
  border-bottom: $border-double double $text-color;
}

#module-header-left {
  text-align: left !important;
}

#module-header-center {
  text-align: left !important;
}

#module-header-right {
  text-align: right !important;
}


.main-container{
  display: flex;
  flex-direction: column;
  gap: $spacing-m;
  padding: 0 $spacing-website;
}



