.priceguide-wrapper{
   width:100%;
   display:flex;
}

.category-area {
   margin: $spacing-m $spacing-l 0 0;
   padding: $spacing-xs;
   border: $border-width solid $dark-gray;
   height: 30rem;
   overflow-y: scroll;
   font-size: $text-xs;
   cursor: pointer;
   background-color: $input-background;
}

.selected {
   font-weight: bold;
}

.priceguide-list-header{
   display:flex;

   .priceguide-header-error {
      text-align: right;
      color:$error-color;
      font-size: $text-s;  
      font-family: $title-font;
   }

   .hidden {
      display: none;
   }
}

.product-area {
   margin: $spacing-m 0 0 0;
   padding: $spacing-xs;
   border: $border-width solid $dark-gray;
   height: 30rem;
   overflow-y: scroll;
   font-size: $text-xs;
   cursor: pointer;
   background-color: $input-background;
}

.selected {
   font-weight: bold;
}


.priceguide-product-row{
   display:flex;

   .priceguide-product-row-last{
      text-align: right; 
      padding-right: 5px;
   }
}