﻿@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap');
//Fonts

$title-font: 'Noto Sans', sans-serif;
$report-font: 'Arial', sans-serif;

$letter-spacing: 0.05rem;
$font-bold: 500;

$primary-color: #336A7C; // For branding, links, and key elements
$secondary-color: #C2D7E8; // For secondary elements
$background-color: #EDEDED; // Main background color
$text-color: #2D3F49; // Default text color
$secondary-text-color: #5A5A5A; // Less prominent text, like captions or metadata
$hover-color: #1F3A47; // Hover and active states
$accent-color: #6B9854; // Highlights, buttons, or active links

// Grayscale Palette
$element-color: #f4f5f7;
$input-background: #FFFFFF; // For subtle backgrounds, dividers, and UI elements
$light-gray: #eff2f5; // For subtle backgrounds, dividers, and UI elements
$dark-gray: #c0c7d1; // For emphasis or secondary text

// Functional Colors
$placeholder-color: #e1e2e5; // Placeholder text in inputs
$error-color: #C0392B; // Error messages or validation
$success-color: #27AE60; // Success messages or validation
$warning-color: #ecb24e; // Warning messages or alerts

$row-height: 30px;
$row-height-small: 24px;
$site-border-radius: 5px;
$border-width: 1px;
$border-width-focus: 2.5px;
$border-double: 4px;

//Font-size

$text-module-header: 1.5rem;    // Medium, for body text (base size)

$text-xxxl: 3.42rem; // Extra-extra-extra large, ideal for big headings
$text-xxl: 2.71rem;  // Extra-extra large, for primary headings
$text-xl: 2.14rem;   // Extra large, for secondary headings
$text-l: 1.71rem;    // Large, for subheadings
$text-lm: 1.15rem;    // Medium, for body text (base size)
$text-md: 1.1rem;    // Medium, for body text (base size)
$text-m: 1rem;    // Medium, for body text (base size)
$text-s: 0.9rem;    // Small, for secondary text
$text-xs: 0.8rem;   // Extra small, for fine print

//Spacing

$spacing-website-xxl: 20rem;    // wide screen website padding
$spacing-website: 12rem;    // Main website padding

$spacing-xxl: 6rem;    // Extra-extra large, for significant padding/margins
$spacing-xl: 4rem;     // Extra large, for large gaps
$spacing-l: 2rem;      // Large, for sections or containers
$spacing-m: 1rem;      // Medium, for standard padding/margins
$spacing-sm: 0.75rem; // Small-medium, for intermediate spacing
$spacing-s: 0.5rem;    // Small, for compact spacing
$spacing-xs: 0.25rem;  // Extra small, for minimal gaps
$spacing-xxs: 0.125rem; // Extra-extra small, for tiny adjustments


$spacing-suffix: calc(calc(1rem + 0.5rem) - calc((1rem + 0.5rem) * 2) );
$spacing-prefix: calc(3rem - calc(3rem * 2));
$circle: 80rem;


h5{
    font-size: $text-m !important;
    font-family: $title-font !important;
}

// /* MIXINS */
// @mixin renderTabletNavView {
//   @media only screen and (max-width: 950px) and (min-width: 600px) {
//     @content;
//   }
// }

// @mixin renderMobileNavView {
//   @media only screen and (max-width: 599px) {
//     @content;
//   }
// }

// @mixin removeNavBarPadding {
//   @media only screen and (max-width: 1099px) {
//     @content;
//   }
// }

// @mixin reduceNavBarPadding {
//   @media only screen and (max-width: 1472px) and (min-width: 1100px) {
//     @content;
//   }
// }
