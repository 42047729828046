.introduction-header {
   font-size: $text-m;
   font-weight: bold;
   font-family:$report-font;
   background: $secondary-color;
   padding: 0 $spacing-s;
   color: $text-color;
   border-radius: $site-border-radius $site-border-radius 0px 0px;
   margin-top: $spacing-s;
}

.introduction-text{
   background-color: $background-color;
   padding: $spacing-s $spacing-m;
   border-radius: 0 0 $site-border-radius $site-border-radius;
   font-size: $text-s;
}