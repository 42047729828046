.tg-dialog-frame {
   max-height:90vh;
   min-width:40vw;
   // width:95vh;
}

.tg-dialog-row {
   display:flex;
   min-height:5vh;
   margin-bottom:10px;
   
}

.tg-dialog-button {
   flex:2;
   border:1.5px solid rgb(151, 217, 158);
   border-radius:10px;
   text-align: center;
   font-weight: bold;
   margin-top:-3px;
   margin-right:20px;
   max-height:34px;
   max-width:80px;
   cursor:pointer;
   color:white;
   font-size: $text-m;
   box-shadow: 6px 5px white, 6px 5px 0 1.5px rgb(151, 217, 158);
   font-family:'Gothic A1', sans-serif;
   display: flex;
   justify-content: center;
   align-content: center;
   flex-direction: column;
}


.tg-dialog-button:hover {
   opacity: 0.5;
}

.tg-dialog-description {
   flex:3;
   padding:4px;
   padding-left:15px;
   padding-right:15px;
   border:1px solid #ccc;
   margin-right:10px;
   text-align: center;
}


.tg-dialog-details {
   flex:12;
   padding:4px;
   border:1px solid #ccc;

   ul {
      margin-left:20px;
      list-style-type:disc;
   }
}

.tg-dialog-details-disabled {
   border:0;
}

.tg-class-0{
   background-color: #00C566;
}

.tg-class-1{
   background-color: #009950;
}

.tg-class-2{
   background-color: #fff000;
}

.tg-class-3{
   background-color: #ff1901;
}

.tg-class-4{
   background-color: #d9d9db;
}
.tg-class-none-2 {
   background-color: #f8f8f8;

}

