@import 'src/assets/style/scss/base/variables.scss';

.contactbutton {
  background-color: $primary-color;
  font-family: $title-font;
  font-size: $text-m;
  color: $element-color;
  padding: $spacing-xxs $spacing-s;
  cursor: pointer;
  border-radius: $site-border-radius;
  text-align: center;
  width: fit-content;

  &:hover {
    background-color: $hover-color;
    color: $element-color;
    cursor: pointer;
  }
  
  }