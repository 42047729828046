.module {
    padding: 0 0 $spacing-s 0;
}

.module-row {
    display: flex;
    gap: $spacing-m;
    width: 100%;
    font-size: $text-m;
    align-items: center;
    margin-bottom: $spacing-xs;
}

.module-row-title {
    font-size: $text-xs;
}

#module-row-no-margin {
    margin-bottom: 0 !important;
}

.module-row-title {
    font-size: $text-s;
}

.module-row-title-big {
    font-size: $text-m;
}

.module-row-title-small {
    font-size: $text-xs;
}

.module-section-nomargin {
    margin-bottom: 0px !important;
}

.module-row-header-medium {
    font-size: $text-md;
    font-weight: bold;
    text-align: right;
}

.module-row-header {
    font-weight: 700;
    text-align: right;
    font-size: $text-s;
}

.module-text-red{
    color: $error-color;
    font-size:$text-xs;
    font-weight: $font-bold;
    margin: $spacing-xs 0;
}