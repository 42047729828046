
.detailed-description-container {
  display:flex;
  margin-left:20px;
  margin-top:4px;
}
.detailed-description-title {
  flex:3;
  padding-left:10px;
}
.detailed-description-content {
  flex:12;
  padding-left:10px;
}
.detailed-description-menu {
  flex:1;
  margin-left:10px;
}

.detailed-description-type-text {
  flex:7;
}

.detailed-description-image-frame {
  flex:3;
  margin-left:10px;
  img {
    max-height:144px;
  }
}
