//Hero-section

.hero-section {
  background: url("/Content/images/home_page/header/house_blue.png") bottom / cover no-repeat;
  justify-content: end;
}

.hero-card {
  background-color: $element-color !important;
  display: flex;
  flex-direction: column;
  margin: $spacing-l $spacing-m;
  padding: $spacing-xl;
  gap: $spacing-l;
  border-radius: $site-border-radius;
}

.hero-card-image{
  width: 10rem;
}

.hero-card-text{
  display: flex;
  flex-direction: column;
  gap: $spacing-m;
}

.hero-card-title {
  color: $primary-color;
  font-size: $text-m;
  font-family: $title-font;
  font-weight: bold;
}

.hero-card-content {
  color: $primary-color;
  font-size: $text-m;
  font-family: $title-font;
  line-height: 1.5;
}


//Customer-review

.customer-review-container{
  display: flex;
  padding: $spacing-xl $spacing-xxl;
  gap: $spacing-l;
  align-items: start;
  justify-content: center;
  color: $primary-color;
  font-size: $text-m;
  font-family: $title-font;
  line-height: 1.5;
}

.customer-review-name{
  font-weight: bold;
  font-size: $text-lm;
}


//Tjenester

.services-container {
  padding: $spacing-xxl;
  background-color: $accent-color;
  display: flex;
  flex-direction: row;
  gap: $spacing-xxl;
  align-items: center;
  justify-content: center;
}

.services-card {
  background-color: $element-color !important;
  display: flex;
  flex-direction: column;
  padding: $spacing-l;
  gap: $spacing-m;
  border-radius: $site-border-radius;
  color: $primary-color;
  font-size: $text-m;
  font-family: $title-font;
  line-height: 1.5;
  justify-content: start !important;
  align-items: start !important;
  width: 25rem;
  height: 18rem;
}

.services-card-title {
  color: $primary-color;
  font-size: $text-lm;
  font-family: $title-font;
  font-weight: bold;
  margin-top: $spacing-m;
}


//Advantages

.advantages-container {
  display: flex;
  padding: $spacing-xl;
  gap: $spacing-xl $spacing-xxl;
  color: $primary-color;
  font-size: $text-m;
  font-family: $title-font;
  line-height: 1.5;
  justify-content: center;
  flex-wrap: wrap;
}


.advantages-card {
  background-color: $input-background !important;
  display: flex;
  gap: $spacing-l;
  border-radius: $site-border-radius;
  color: $primary-color;
  font-size: $text-m;
  font-family: $title-font;
  line-height: 1.5;
  justify-content: center !important;
  align-items: center !important;
  width: 25rem;
  height: 10rem;
  padding: $spacing-m $spacing-l;
}

.advantages-card-title {
  color: $primary-color;
  font-size: $text-m;
  font-family: $title-font;
  font-weight: bold;
  margin-top: $spacing-m;
}

.advantages-card-content {
  color: $primary-color;
  font-size: $text-m;
  font-family: $title-font;
  line-height: 1.5;
}


// Priser

.home-price-container {
  background-color: $accent-color;
  display: flex;
  justify-content: center;
  align-content: center;
  gap: $spacing-s;
}

.home-price-card {
  border-radius: $site-border-radius;
  background-color: $element-color;
  display: grid;
  grid-template-rows: 0.5fr 1fr 0.7fr 0.3fr;
  padding: $spacing-l $spacing-xl $spacing-m $spacing-xl;
  margin: $spacing-l $spacing-xl;
  color: $primary-color;
  font-size: $text-m;
  font-family: $title-font;
  line-height: 1.5;
  gap: $spacing-m;
  width: 35rem;
}

.home-price-card-title {
  color: $primary-color;
  font-size: $text-lm;
  font-family: $title-font;
  font-weight: bold;
}
.home-price-card-subtitle {
  color: $primary-color;
  font-size: $text-l;
  font-family: $title-font;
  font-weight: bold;
  margin-top: $spacing-s;
  gap: $spacing-s;
  display: flex;
}

.home-price-card-list {
  display: flex;
  flex-direction: column;
  gap: $spacing-s;
}

.home-price-card-info-text{
  display: flex;
  gap: $spacing-m;
  align-items: center;
}




