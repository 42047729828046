﻿.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: $spacing-m $spacing-website
}

.navbar-logo {
  width: 10rem;
  cursor: pointer;
}

.navbar-button-container {
  display: flex;
  align-items: center;
  gap: $spacing-m
}

.navbar-button {
  cursor: pointer;
  font-size: $text-m;
  color: $primary-color;
  font-family: $title-font;

  &:hover {
    color:$hover-color;
  }

}

.navbar-user-menu{
  color: $primary-color !important;
  cursor: pointer;
  margin-top: $spacing-xs;
}

.navbar-dropdown-menu-text{
  display: flex;
  gap: $spacing-sm;
  color: $primary-color !important;
  opacity: 1 !important;
  font-family: $title-font !important;
}

.navbar-dropdown-menu-btn{
 text-decoration: underline !important;
}