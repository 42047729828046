@import '../../../assets/style/scss/base/variables.scss';
.module-header {
    font-size: $text-module-header !important;
    color: $hover-color;
    border-radius: $site-border-radius !important;
    font-family: $report-font;
    padding: 0 $spacing-s;
 }

 .module-color-default{
    background-color: $dark-gray;
 }

 .module-color-shared{
    background-color: $warning-color;
 }

 .title {
    flex: 10;
 }

 .module-header-icon{
    min-width: $spacing-m;
    font-size: $text-lm;
 }