
.area-spec-wrapper {

.area-spec-section-header {
  color: $input-background;
  background: $text-color;
  padding-left: $spacing-s;
  margin-bottom: $spacing-s;
  display: flex;
  font-size: $text-l;

}

.area-spec-header {
    font-size: $text-xl;
    color: white;
    padding-left: $spacing-s;
    margin-bottom: $spacing-s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.area-spec-measurement-standard {
    display:flex;

    .area-spec-measurement-legend {
      color: $text-color;
      flex:1;
      margin-bottom:$spacing-xs;
    }
      
    .area-spec-measurement-standard-select {
      flex:10;
      padding-left:$spacing-xs;
      .area-spec-control-large{
        font-family: 'Arial';
        font-size:$text-m;
      }
     
    }
}

.area-spec-description-type{
    flex:12;
    padding-left:0px;
    display:flex;
    
    .area-spec-description-type-text{
      flex:7;
    }
  }

  .area-spec-category-row {
    border-left:$border-width solid $hover-color;
    border-bottom:$border-width solid ;
  }
  .area-spec-category-border-r-l{
    border-left:$border-width solid $hover-color;
    border-right:$border-width solid $hover-color;
  }

  .area-spec-input{
    background:transparent !important;
    border:0;
    width:100%;
    font-size: $text-m;
  }
   .area-spec-input-reduced {
    background:transparent !important;
    border:0;
    width:calc(100% - 20px);
    font-size: 1em;
    padding-top: $text-m;
  }
  
  .area-spec-building-input {
    margin: 0 0;
    padding: $spacing-xs 0;
    display:flex;

    .area-spec-building-input-caption {
      flex:4;
      //font-size: 18px;
      font-weight: bold;
      padding-left:$spacing-s;
      padding-top:$spacing-xs;
    }

    input {
      
    }
  }

  .area-spec-building-separator {
    padding-bottom:$spacing-l;
    margin-bottom:$spacing-l;
  }

  .calculated-value {
    input:focus { 
      outline: none !important;
      border: none !important;
    }
  }

  .area-spec-building {
    .bkbutton {
       padding-top: 0px;
    }

    .area-spec-table-heading{
      display:flex;
    }
    .action-menu {
      color:white;
      padding-right:$spacing-s;
      .navbar-profile {
        margin-left: $spacing-l;
      }
    }
    .area-spec-table-heading-wrapper {
      text-align: center;
      background-color: $secondary-color;
      border-left:$border-width solid  $hover-color;;
      border-bottom:$border-width solid $hover-color;;
      vertical-align: center;
      padding-top:$spacing-xxs;
    }
    .area-spec-heading-item {
      color:white;
      text-align: center;
      font-size: $text-l;
    }    
    .area-spec-vertical-align {
      padding-top:$spacing-m;
    }
    .area-spec-heading-row {
      display:flex;
      border-top:$border-width solid  $hover-color;;
    }
    .area-spec-heading-menu{
      flex:0.5;
      padding-left:$spacing-m;
      cursor:pointer;
    }

    .area-spec-building-width-of-table {
      width: 96%;
    }
    .area-spec-building-summary {
      background-color: $secondary-color;
      color:$input-background;
      font-size: $text-l;
    }
    // .text-is-white * {
    //   background-color: white !important;
    // }
  }


  .area-spec-border-right {
    border-right:$border-width solid $hover-color;;
  }

  .area-header-margin {
    margin-top:70px;
  }

  .area-spec-is-summary {
    border:none;
    .area-spec-category-row {
      background-color: $light-gray;
      //padding-top:4px;
    }

  }

  .area-spec-is-total-summary {
    border:none;
    .area-spec-category-row {
      background-color: $light-gray;
    }

  }

  .area-spec-is-total-row {
    border:none;
    .area-spec-category-row {
      background-color: $secondary-color;
    }

  }

  .area-spec-categoryRowInput {
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-right: 0px;
    span {
      font-size:$spacing-m;
      margin-top: $spacing-xxs;
      padding-right:$spacing-s;
      padding-left:$spacing-xs;
    }
  }
}



  
  



  