.module-content-row-declaration{
    display: flex;
    gap: $spacing-l;
    align-items: center;
}
.module-content-column-declaration{
    display: flex;
    flex-direction: column;
    gap: $spacing-m;
    justify-content: center;
    align-items: center;
    margin: $spacing-s;
}

.labeled-check-btn{
    display: flex;
    align-items: center;
    gap: $spacing-m;
}

.liability-input-field{
    height: 1.5rem;
}

.area-content-grid-declaration{
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 3fr;
    gap: $spacing-m;
    align-items: center;
    margin: 0 $spacing-m;
    padding-bottom: $spacing-m;
    border-bottom: $border-width solid rgb(210, 210, 210);

   .area-column-declaration{
        display: flex;
        flex-direction: column;
        gap: $spacing-s;
        font-weight: bold;
        font-size: small;
   }
}

.regulation-list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $spacing-s;
    font-family: $title-font;
    font-weight: bold;
    padding: $spacing-m;
    width: 85%;
}

.application-list{
    display: flex;
    flex-direction: column;
    font-family: $title-font;
    gap: $spacing-s;
    font-weight: bold;
    font-size: small;
    background-color: $element-color;
    padding: $spacing-m $spacing-l;
}