.upload-image-frame-no-image {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: $border-width dashed $primary-color;
  height: 100%;
  width: 100%;
  color: $primary-color;
  padding: $spacing-m $spacing-s;

  .upload-image-no-image-icon {
    font-size: $text-xxxl;
    margin-bottom: $spacing-s;
  }

  .upload-image-no-image {
    font-family: $title-font;
    font-size: $text-m;
  }
}

.upload-image-frame {
  height: 100%;
  width: 100%;
  cursor: pointer;
  text-align: center;
  border: $border-width solid $primary-color;
}

.upload-image-img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  vertical-align: top;
  margin: auto;
  overflow: hidden;
}

.approval-mark-picture {
  margin-bottom: $spacing-m;
  margin-top: $spacing-m;

  .upload-image-frame {

    .upload-image-img {
      height: 14rem;
    }
  }

  .upload-image-frame-no-image {
    height: 14rem;
  }

}