.phrase-text-container {
   max-height: 80vh;
   min-height: 60vh;
   width: 100%;

   .phrase-text {
      display: flex;
      padding: $spacing-s $spacing-m !important;
      background-color: $background-color;
      gap: $spacing-m;
      align-items: start;

      .phrase-text-checkbox {
         width: $spacing-l;
         padding-top: $spacing-xs;
         height: $spacing-l;
      }

      .phrase-text-content {
         flex: 10;

         .phrase-text-system {
            background-color: #efefef;
         }

         .phrase-text-focus {
            border: 1px solid red;
         }
      }

      .phrase-auto-text-content {
         position: relative;
         flex: 11;

      }

      .phrase-text-icon {
         width: 40px;
         height: 30px;
         cursor: pointer;
         font-size: $text-l;
         padding-top: $spacing-xs;
         padding-left: $spacing-m;
         text-align: center;
      }
   }

   .phrase-text-no-phrases {
      text-align: center;
      font-size: $spacing-l;
      padding-top: 20%;
      color: $primary-color;
      font-family: $title-font;
   }

}

.phrase-auto-text-copy-btn {
   padding: $spacing-s 0;
}

.phrase-input-line {
   display: grid;
   grid-template-columns: 0.5fr 2fr 1fr;
   gap: $spacing-m;
   background-color: $secondary-color;
   padding: $spacing-s $spacing-m;
   align-items: center;
   font-family: $title-font;
}

.dialog-buttons {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: $secondary-color;
   gap: $spacing-s;
}