.detailed-calculation-header{
  text-align: center;
  background-color: #a0c2de;
  border-left:$border-width solid  white;
  border-bottom:$border-width solid white;
  vertical-align: center;

  .detailed-calculation-item {
    color:$text-color;
    font-weight: bold;
    font-size: $text-m;
    text-align: center;
  }

  .single-line {
    padding-top:$spacing-l;
  }

  .double-line {
    padding-top: $spacing-m;
  }

  .border-right {
    border-right:$border-width solid  #E3EDF9;
  }
  

  .heading-row {
    display:flex;
    border-top:$border-width solid  #E3EDF9;
  }


}

.curved-top-left{
  border-top-left-radius: 6px;
}
.curved-top-right{
  border-top-right-radius: 6px;
}

.compressed-header {
  flex:12;
  padding-right: 20px;
}
.detailed-calculation-heading-menu{
  display: flex;
  flex:0.2;
  padding-left:16px;
  padding-right:3px;
  padding-top:10px;
  cursor:pointer;
  font-size:$text-xl;
  justify-self: center;
}

.category-row {
  margin-bottom:4px;



  .category-row-cell {
    border-left:$border-width solid #E3EDF9;
    border-bottom:$border-width solid #E3EDF9;
  }

  .category-row-value {
    padding-right: 7px;
  }

  .category-row-input{
    background:transparent;
    border:0;
    width:100%;
    font-size: 1em;
    padding-right:6px;
  }

  .category-row-input:focus{
    background:transparent !important;
    border-radius: 0 !important;
  }
  
  .category-row-input-reduced {
    background:transparent;
    border:0;
    width:calc(100% - 20px);
    font-size: 1em;
  }

  
  .category-row-select{
    background:transparent;
    border:0;
    width:100%;
    font-size: 1em;
    text-align-last:right;
  
    option {
      direction:rtl;
    }
  }
  
  .category-row-menu{
    flex:0.2;
    padding-left:20px;
    cursor:pointer;

    div {
      margin-left:2px;
    }
  }
}

.main-color-even {
  background-color: $light-gray;
}
.main-color-odd {
  background-color: $light-gray;
}

.light-color-even {
  background-color: $light-gray;
}
.light-color-odd {
  background-color: #dae7f1;
}


.detailed-calculation-sum-title {
  background-color: $light-gray;
  font-weight: bold;
  padding-left:10px;
}

.detailed-calculation-sum-value{
  font-weight:bold;
  text-align: right;
  padding-right:10px;
}

.detailed-calculation-print-select{
  margin-top:10px;
  width:100%;
  text-align: right;
  font-size: $text-l;
}