.contact-container {
  background-color: $element-color !important;
}

.contact-title {
  color: $primary-color;
  font-size: 18px;
  font-family: $title-font;
}



.contact-button {
  text-align: end;
}

.contact-text {
  font-family: $title-font;
  color: $primary-color;
  font-size: $text-m;
}
