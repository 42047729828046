@import 'src/assets/style/scss/base/variables.scss';
  
.bkbutton-small {
  text-align: center;
  font-size: $text-s;
  color: $input-background;
  background-color: $accent-color;
  border-radius: $site-border-radius;
  font-family: $title-font;
  padding: $spacing-xxs $spacing-s;
  cursor: pointer;
  width: fit-content;

  &:hover {
    background-color:$hover-color;
    color:$input-background;
  }
}
.bkbutton-disabled {
  background-color: $dark-gray !important;
  cursor: auto !important;
}

.bkbutton-blue{
  background-color: $primary-color;
}

.bkbutton-red{
  background-color: $error-color;
}
