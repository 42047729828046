@import '../../../assets/style/scss/base/variables.scss';

.category-header-module {
   font-size: $text-lm !important;
   font-weight: bold;
   color:$text-color;
   font-family:$report-font;
   background: $placeholder-color;
   border-radius: $site-border-radius $site-border-radius 0 0 ;
   width: 100%;
   display:flex;
   margin-top: $spacing-s;
   padding: $spacing-xxs $spacing-m;
   min-height: 2rem;
   margin-bottom: 0 !important;
}

.category-header-module-blank{
   font-size: $text-l;
   font-weight: bold;
   font-family:$report-font;
   color:$text-color;
   display:flex;
   height:28px;
   padding-top:$spacing-xxs;
   margin-top: $spacing-m;

   input {
      margin-left:0 !important;
   }
}
