.invoice-container {
   width: 100%;
   text-align: center;
 }

 
 .invoice-control {
  width: 100% !important;
  color: $primary-color !important;
    font-size: $text-m !important;
    font-family: $title-font !important; 
 }

 .invoice-control-container{
    text-align: center;
    color: #44758C;
 }


 .invoice-button {
    text-align: center !important;

   .bkbutton {
      width:200px !important;
      margin-left:auto;
      margin-right: auto;
   }
 }
 
 .invoice-heading {
   margin-top: 15px;
   color: $primary-color;
   font-size: $text-l;
   text-align: center;
   font-family: $title-font;
   font-weight: bold;
   margin-bottom:20px;
 }
 
 .invoice-heading-small {
   margin-left: 20px;
   color: $primary-color;
   font-size: $text-m;
   font-family: $title-font;
   margin-bottom:10px;
   margin-top: 7px;
 }

 .invoice-subtitle-flex{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
 }

 .invoice-control-checkbox {
  color: $accent-color !important;
 }
 .invoice-control-checkbox-label {
  margin: 0 !important;
  color: $primary-color !important;
 }
 