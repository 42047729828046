.content-row-declaration{
    display: flex;
    align-items: center;
    gap: $spacing-m;
    margin-bottom: $spacing-s;
    font-size:$spacing-m;
}

.pdf-icon{
    color: $error-color;
    cursor: pointer; 
}

.download-icon{
    color:black;
    cursor: pointer;
    font-size:$spacing-m;
}

.pdf-link{
   cursor: pointer;  
}

.PDF_delete{
    cursor: pointer; 
    color: $error-color;
}