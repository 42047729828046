@import '../../../assets/style/scss/base/variables.scss';


.section-header-module {
   align-items: center;
   font-size: $text-lm !important;
   font-weight: bold;
   color:$text-color;
   background: $secondary-color;
   border-radius:$site-border-radius $site-border-radius 0 0 ;
   width: 100%;
   margin-top: $spacing-s;
   padding: $spacing-xxs $spacing-m;
   min-height: $spacing-m;
   margin-bottom: 0 !important;
}

.description{
   flex: 10;
}

.description-mini {
   flex: auto;
}

.description-medium {
   flex: 7.5;
}

.input-field {
   flex: 3;
}

.action-menu{
   display: flex;
   gap: $spacing-m;
   justify-content: center;
   flex: 0.5;
}

.section-header-icon{
   min-width: $spacing-m;
   font-size: $text-xl !important;
}
