.header-message-container{
    display: grid;
    grid-template-columns: 5fr auto;
    gap: $spacing-l;
    text-align: center;
    font-size: $text-s;
    font-family: $title-font;
    font-weight: 600;
    color: #2D2927;
    justify-content: center;
    align-items: center;
    padding:$spacing-s $spacing-xl;
  }
  
  .header-message-inner-container{
    display: flex;
    justify-content: center;
    gap:$spacing-m
  }
  .message-container{
    display: flex;
    flex-direction: column;
    padding-top: $spacing-m;
    gap:$spacing-s
  }
  
  .bg-update{
    background-color:$warning-color;
  }
  .bg-message{
    background-color: $success-color;
  }
  
  .header-message-close-icon{
    font-size: $text-l;
    cursor: pointer;
  }
  .header-message-info-icon{
    font-size: $text-l;
  }