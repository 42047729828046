@import '../../../assets/style/scss/base/variables.scss';

.categoryinput-header {
   font-size: $text-l;
   font-weight: bold;
   font-family:$report-font;
   color:black;
   background: $light-gray;
   padding-left:$spacing-s;
   border-radius: $site-border-radius $site-border-radius 0px 0px;
   display:flex;
   height:28px;
   padding-top:$spacing-xxs;
   margin-top: $spacing-xs;


   .action-menu {
      text-align: right;
      flex:1;
      margin-left:$spacing-s;
      padding-top: $spacing-xxs;
      padding-right:46px;
      cursor:pointer;
      min-width:150px;

   
      svg {
         float:right;
         margin-top:$spacing-xxs;
         font-size: $text-m;
         margin-left:$spacing-m;
         color:$text-color;
      }
   }

   .input-field {
      flex:13;
      padding-left:$spacing-s;
      display:flex;
      margin-top: 0px;
      margin-bottom: $spacing-xxs;


      input {
         margin-top:$spacing-xxs;
         max-width:250px;
         padding-left:$spacing-xs;
         padding-bottom:$spacing-xxs;
         padding-top:$spacing-xxs;
         background-color: $element-color;
         color:black;
         font-size: $text-m;
         outline: none !important;
         border: none !important;
         box-shadow: none!important;
      }

      input:focus {
         box-shadow: none !important;
      }

      input::placeholder {/* Chrome, Firefox, Opera, Safari 10.1+ */
         color: $placeholder-color;
         opacity: 1;/* Firefox */
      }
      input:-ms-input-placeholder { /* Internet Explorer 10-11 */
         color: $placeholder-color;
       }
       
       input::-ms-input-placeholder { /* Microsoft Edge */
         color: $placeholder-color;
       }
   }

   .search-icon {
      margin-top:$spacing-xxs;
      margin-right:$spacing-xs;
      min-width:10px;
   }

}