.summary-module-section {
   margin-left:10px;
   margin-top:20px;
   margin-right: 10px;
   margin-bottom:30px;
}

.summary-module-grade-container {
  // display: flex;
   // flex-wrap: wrap;
   width: 100%;
   margin-bottom:20px;

   .summary-module-tg{
      text-align: left;
      font-weight: bold;
      margin-right:100px;
      margin-left:$spacing-l;
      color:white;
      font-size: $text-l;
      justify-content: left;
      align-content: left;
      flex-direction: column;
      min-height:70px;
      max-height:70px;
      border-radius:$site-border-radius;

      .summary-module-number{
         color:white;
         font-size: $text-s;
         border:0;
         margin-top:-8px;
      }

      .summary-module-tg-text{
         width:100px;
         padding:$spacing-m $spacing-l $spacing-m $spacing-xs;
         text-align: center;
         //font-family:'Gothic A1', sans-serif;
         font-size:$text-m;
      }




   }

   .summary-module-sum{
      font-size: $text-s;
      color:#DD392C;
      text-align:center;
   }
   .summary-module-categories{
      margin-left:100px;
      margin-right:$spacing-l;
      margin-top:-65px;
      border:$border-width solid #aaa;
      padding:$spacing-xs;
      min-height:70px;
      background-color: white;
      border-radius:$site-border-radius;

      .summary-module-categories-container{
         border-left:3px solid #aaa;
         padding-left:$spacing-s;
         min-height:50px;
         margin: $spacing-xs $spacing-xs $spacing-xs $spacing-m;
      }
   }

   .summary-module-number{
      flex:1;
      border:$border-width solid #aaa;
      padding: $spacing-xs;
   }     
}

hr.summary-line{
      border-top: 2px solid #DD392C !important;
      opacity: 10 !important;
}

.summary-module-category-container{
   margin-bottom:2px;

   .summary-module-category-row{
      font-size:$text-s;
      font-weight:bold;
   }
   
   .summary-module-assessment-row{
      font-size:$text-s;
      font-weight:normal;
      margin-left:$spacing-m;
   }
   
   
   .summary-module-assessment-row:last-of-type{ 
      margin-bottom:$spacing-s;
   }
}


