.search-bar{
  font-size: $text-s !important;
  padding: $spacing-xs !important;
}

.contacts-row {
  display:flex;
  width: 100%;
  cursor:pointer;
  padding-top:$spacing-xs;
  padding-bottom:$spacing-xs;
  padding-left:$spacing-s;
  height:32px;

  div {
    overflow: hidden;
    white-space: nowrap;
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    margin-right:$spacing-m;
    display:block;
  }
}

.contacts-row:hover {
  background-color: $primary-color !important;
  color:$input-background;
}

.contacts-row-selected {
  background-color: rgba(106,157,158,1) !important;
  color:$input-background;
}

.contacts-row-checkbox {
  padding:0px !important;
  margin-top: $spacing-xs;
}

.no-padding{
  padding:0px !important;
}

.background-odd {
  background-color: $input-background;
}

.background-even {
  background-color: $light-gray;
}

.contact-dialog{
  min-height:400px;
}


.contacts-search {
  flex:1;
  text-align: right;;
  margin-right:1vw;
  min-width:400px;
  margin-top:-4px;
  input {
    width:100% !important;

  }
}

.contact-dialog-content-text{
  font-size: 15px;
}

.contact-dialog-button{
   max-width: 10vw;      
   background-color: #D1E0E1;
   color: #334652;
   font-size: 0.8vw;
   font-weight: bold;
   margin-top: 0.5vw;
   margin-right: 1vw;
   padding-left: 1.3vw;
   padding-right: 1.3vw;
   padding-top: 0.4vw;
   height: 2vw;
   min-width: 6vw;
   cursor:pointer;

   &:hover {
      background-color:rgba(106,157,158,1);
   }
}

.contact-dialog-button-row{
  display:flex;
}

.contact-dialog-add-button{
  display: block;
  justify-content: end;
}

.contact-dialog-search{
  input {
    width:100% !important;
    font-family: 'Orienta', sans-serif !important;
  }

}
