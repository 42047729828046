.bk-container {
   flex: auto;
   width: 100%;
   background: $background-color;
   padding-bottom: $spacing-xl;
}

.bk-input-container {
   display: inline-flex;
   width: 100%;
   align-items: center;
}

.bk-input-field {
   display: flex;
   border-radius: $site-border-radius;
   border: $border-width solid $dark-gray;
   background: $input-background;
   font-size: $text-m;
   padding: 0 $spacing-l 0 $spacing-s;
   width: 100%;
}

.bk-input-field:disabled {
   background: $placeholder-color
}

.bk-input-suffix {
   margin-left: $spacing-suffix;
   font-size: $text-xs;
}

.bk-input-prefix {
   margin-right: $spacing-prefix;
   z-index: 2;
}

.bk-input-field:focus {
   // border: $border-width-focus solid ;
   outline: $border-width-focus solid $secondary-color !important;
}

.bk-input-field:focus-visible {
   // border: $border-width-focus solid ;
   // outline: none !important;
}

.bk-input-field-static-display {
   cursor: pointer !important;
   padding: 0;
   text-align: center;
}

.bk-input-field-static-display:focus {
   // border: $border-width-focus solid $primary-color;
   // outline: none !important;
   caret-color: transparent;
}

.bk-input-align-center{
   text-align: center;
   padding-right: 0 ;
}

.bk-date-picker {
   display: grid;
   grid-template-columns: 4fr 1fr;
   align-items: center;
}

.bk-input-field-sum {
   background-color: $light-gray !important;
}

.bk-input-field-select {
   padding: $spacing-xxs $spacing-xs;
}

.bk-input-field-select-big {
   padding: $spacing-xs ;
   font-size: $text-s;
}

.bk-instruction-text {
   background-color: $placeholder-color;
   user-select: none;
   border-radius: $site-border-radius;
   padding: $spacing-s;
   font-size: $text-s;
}

.MuiIconButton-root:read-only:hover {
   background-color: transparent !important;
}

.btn_grid {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   column-gap: $spacing-m;
   row-gap: $spacing-m;
}

.bk-report-header {
   display: grid;
   grid-template-columns: 2fr auto auto;
   gap: $spacing-m;
   align-items: center;
   color: $primary-color;
   font-size: $text-xxl;
   font-family: $title-font;
   margin-bottom: $spacing-m;
}

.bk-info-header {
   background-color: $warning-color;
   color: $text-color;
   font-size: $text-s;
   font-family: $title-font;
   margin: 0 0 $spacing-m 0;
   padding: $spacing-xs $spacing-m $spacing-xs $spacing-m;
   border-radius: $site-border-radius;
}

.bk-row-header {
   color: $primary-color;
   font-size: $text-xxl;
}

// .bk-row-header-title {
//    font-family: $title-font;
//    letter-spacing: $letter-spacing;
//    font-weight: $font-bold;
// }

.bk-page-section {
   margin-left: $spacing-m;
   margin-top: $spacing-l;
   margin-right: $spacing-l;

   .bk-paragraph {
      margin-top: $spacing-l;

      .bk-paragraph-header {
         font-weight: bold;
         font-size: $text-l;
      }

      .bk-paragraph-content {
         margin-left: $spacing-l;
      }
   }

   .bk-section {
      margin-left: $spacing-l;
      margin-bottom: $spacing-xl;
      margin-right: $spacing-l;

      .header {
         font-size: $text-l;
         font-weight: bold;
      }

      .text {
         font-size: $text-m;
         margin-left: $spacing-m;
         margin-top: $spacing-xxs;
      }
   }
}

.cursor-pointer {
   cursor: pointer  !important;
}

.bk-text-blue-big {
   color: $primary-color;
   font-size: $text-xl;
   font-family: $title-font;
   letter-spacing: $letter-spacing;
   font-weight: $font-bold;
}

.bk-text-blue-medium {
   color: $primary-color;
   font-size: $text-lm;
   font-family: $title-font;
   letter-spacing: $letter-spacing;
   font-weight: $font-bold;
}

.bk-text-blue-header {
   color: $primary-color;
   font-size: $text-l;
   font-family: $title-font;
   letter-spacing: $letter-spacing;
   font-weight: $font-bold;
}

.bk-text-blue {
   color: $primary-color;
   font-family: $title-font;
   font-size: $text-m
}

.bk-text-white {
   color: $element-color;
   font-family: $title-font;
   font-size: $text-m
}

// .totalsum{
//    background-color:$light-gray !important;  
// }

.bk-header-icon {
   color: $placeholder-color;
}


.bk-small-header {
   font-family: $report-font;
   font-size: $text-m;
}

.bk-edit-comment {
   margin-top: 10px;
   font-size: $text-m;
   color: $primary-color;
}

.bk-edit-comment-input {
   margin-top: 8px;
   font-size: $text-m;
   width: 20vw;
   box-shadow: none !important;
}

.auto-text-box-frame {
   display: flex;
   align-items: center;
   gap: $spacing-s;
}

.bk-footer {
   position: relative;
   bottom: 0;
   left: 0;
   right: 0;
   width: 100%;
   padding-bottom: $spacing-s;
   background-color: none;

   // @media only screen and (max-width: 769px) {
   //    font-size: $text-m;
   // }
}

.bk-footer>div {
   text-align: center;
   font-size: $text-m;
   color: $primary-color;
}

.market-analysis-market-value-input {
   flex: 3;
   font-size: $text-xl !important;
   font-weight: bold;
}

.align-center {
   text-align: center;
 }

.underline {
   padding-bottom: $spacing-s;
   border-bottom: $border-width solid $light-gray;
}

.signature-text {
   text-align: center;
   font-size: $text-s;
}

.bk-calculation-marker-1 {
   background: $light-gray;
   font-weight: bold;
}

.bk-calculation-marker-2 {
   background: $accent-color;
   font-weight: bold;
}

.profile-content{
   background-color: $element-color;
   padding: $spacing-s $spacing-m $spacing-m $spacing-m;
   border-radius: $site-border-radius;
 }

.about-text-content{
   letter-spacing: $letter-spacing;
   font-size: $text-m;
}