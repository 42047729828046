@import '../../../assets/style/scss/base/variables.scss';

.category-header {
   margin-top: $spacing-s;
   font-size: $text-md !important;
}

.status-icon {
   display: flex;
   font-size: $text-m;
   padding-bottom: $spacing-xxs;
   border: $border-width solid $hover-color;
}

.status-icon-green {
   color: $success-color;
   border-radius: $circle;
   border: $border-width solid $hover-color;
}

.status-icon-yellow {
   color: $warning-color;
   border-radius: $circle;
   border: $border-width solid $hover-color;
}

.status-icon-red {
   color: $error-color;
   border-radius: $circle;
   border: $border-width solid $hover-color;
}

.status-icon-transparent {
   color: transparent;
   border-radius: $circle;
   border: $border-width solid $hover-color;
}

.status-icon-big {
   font-size: $text-m;

}

.tag {
   font-size: $text-xs;
   flex: 3;
}