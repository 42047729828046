.ownership-table {
   .ownership-table-row {
      display:flex;
      

      .ownership-table-delete-img{
         flex:0.5;

         img {
            display: inline-block;
            height: 22px;
            cursor: pointer;
            margin-top: 0;
            margin-left:0;
            filter: opacity(.5) drop-shadow(0 0 0 #ff0000);
          }
      }
   }
}

.ownership-result-row{
   display:flex;
}

.ownership-control-large{
   min-width:250px;
   font-size:$spacing-m;
   box-shadow: none !important;
}

.ownership-control-small{
   min-width:150px;
   font-size:$spacing-m;
   box-shadow: none !important;
}

.ownership-control-max{
   min-width:100%;
   resize: vertical;
   padding:4px;
   font-size:$spacing-m;
   box-shadow: none !important;
}

.ownership-result-borders{
   margin-top:$spacing-xs;
   border-top:$border-width solid black;
   border-bottom:$border-double double black;
}

.ownership-right-margin{
   margin-right:$spacing-m;
}